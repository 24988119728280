import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Upload, Drawer, Form, Input, Button } from 'antd';
import { validateFields, getImageUrl } from '@utils/helper';

import { makeSelectProduct, makeSelectProductLoading, makeSelectProcess } from '../product.selectors';
import { getProductDetailAction, putProductAction } from '../product.actions';
import './style.less';


function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

class ProductDetail extends PureComponent {
  constructor() {
    super();
    this.state = {
      thumbnail: '',
      loading: false,
    }
    this.file = null;
  }
  componentDidMount() {
    this.props.getDetail(this.props.productId)
  }
  handleUpdateProduct = async (e) => {
    e.preventDefault();
    const [err, values] = await validateFields(this.props.form);
    if (!err) {
      const updateData = {
        id: this.props.productId,
        ...values,
        files: [this.file],
      }
      this.props.updateItem(updateData);
    }
  }
  beforeUpload = (file, fileList) => {
    console.log(fileList);
    this.file = file;
    getBase64(file, imageUrl =>
      this.setState({
        thumbnail: imageUrl,
        loading: false,
      }),
    );
    return false;
  }
  render() {
    const { product, productLoading } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Drawer
        title="Cập nhật sản phẩm"
        placement="right"
        onClose={this.props.onClose}
        visible
        width="50%"
      >
        <Form onSubmit={this.handleUpdateProduct} loading={productLoading}>
          <div className='productImageContainer' style={{ backgroundImage: `url(${getImageUrl(product.image)})` }}>
            <Form.Item className='orderImageArea'>
              {getFieldDecorator('files', {
                valuePropName: 'fileList',
              })(
                <Upload className="orderImageUpload" beforeUpload={this.beforeUpload} showUploadList={false}>
                  <Button icon="upload"></Button>
                </Upload>,
              )}
            </Form.Item>
            <img alt={this.state.thumbnail} className='orderImagePreview' src={this.state.thumbnail} />
          </div>

          <Form.Item label="Tên sản phẩm">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Nhớ chọn hoặc nhập tên sản phẩm' }],
              initialValue: product.name
            })(
              <Input />
            )}
          </Form.Item>
          <Form.Item label="Mã sản phẩm">
            {getFieldDecorator('code', {
              initialValue: product.code || ''
            })(
              <Input />
            )}
          </Form.Item>
          <Form.Item label="Định mức">
            {getFieldDecorator('quota', {
              initialValue: product.quota || ''
            })(
              <Input />
            )}
          </Form.Item>
          <Form.Item label="Ghi chú">
            {getFieldDecorator('note', {
              initialValue: product.note || ''
            })(
              <Input.TextArea />
            )}
          </Form.Item>

          <Form.Item>
            <Button disabled={productLoading} loading={productLoading} type="primary" htmlType="submit">Lưu</Button>
          </Form.Item>
        </Form>
      </Drawer>
    );
  }
}

ProductDetail.propTypes = {
  productId: PropTypes.number,
  product: PropTypes.object,
  productLoading: PropTypes.bool,
};

ProductDetail.defaultProps = {
  productId: 0,
  product: {},
  productLoading: false,
};

const mapStateToProps = createStructuredSelector({
  product: makeSelectProduct(),
  productLoading: makeSelectProductLoading(),
  process: makeSelectProcess(),
});

const mapDispatchToProps = dispatch => ({
  getDetail: data => dispatch(getProductDetailAction(data)),
  updateItem: data => dispatch(putProductAction(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const ProductDetailWrapper = Form.create()(ProductDetail)

export default compose(withConnect, memo)(ProductDetailWrapper);
