import {
  GET_LIST_REQUEST,
  GET_LIST_FAILURE,
  GET_LIST_SUCCESS,
  GET_LIST_ITEM_REQUEST,
  GET_LIST_ITEM_FAILURE,
  GET_LIST_ITEM_SUCCESS,
  PUT_ITEM_REQUEST,
  PUT_ITEM_FAILURE,
  PUT_ITEM_SUCCESS,
  ON_CHANGE_FILTER,
  ON_SELECT_ITEM,
} from './constants';

export const getListAction = payload => ({ type: GET_LIST_REQUEST, payload });
export const getListSuccess = payload => ({ type: GET_LIST_SUCCESS, payload });
export const getListFailure = payload => ({ type: GET_LIST_FAILURE, payload });

export const getListItemAction = payload => ({ type: GET_LIST_ITEM_REQUEST, payload });
export const getListItemSuccess = payload => ({ type: GET_LIST_ITEM_SUCCESS, payload });
export const getListItemFailure = payload => ({ type: GET_LIST_ITEM_FAILURE, payload });

export const putItemAction = payload => ({ type: PUT_ITEM_REQUEST, payload });
export const putItemSuccess = payload => ({ type: PUT_ITEM_SUCCESS, payload });
export const putItemFailure = payload => ({ type: PUT_ITEM_FAILURE, payload });

export const onChangeFilterAction = payload => ({ type: ON_CHANGE_FILTER, payload });

export const onSelectItemAction = payload => ({ type: ON_SELECT_ITEM, payload });