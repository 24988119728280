import produce from 'immer';
import {
  GET_MATERIAL_SUCCESS,
  GET_MATERIAL_REQUEST,
  GET_MATERIAL_FAILURE,
  PUT_MATERIAL_SUCCESS,
  PUT_MATERIAL_REQUEST,
  PUT_MATERIAL_FAILURE,
  GET_MATERIAL_WARE_SUCCESS,
  GET_MATERIAL_WARE_REQUEST,
  GET_MATERIAL_WARE_FAILURE,
  POST_MATERIAL_WARE_REQUEST,
  POST_MATERIAL_WARE_SUCCESS,
  POST_MATERIAL_WARE_FAILURE,
  GET_MATERIAL_DETAIL_SUCCESS,
  GET_MATERIAL_DETAIL_REQUEST,
  GET_MATERIAL_DETAIL_FAILURE,
  DELETE_MATERIAL_WARE_SUCCESS,
  DELETE_MATERIAL_WARE_REQUEST,
  DELETE_MATERIAL_WARE_FAILURE,
  ON_CHANGE_FILTER
} from './material.constants';

const DEFAULT_FILTER = {
  name: '',
  type: 0,
  note: ''
}

export const initialState = {
  list: [],
  loading: false,
  postLoading: false,
  material: {},
  materialLoading: false,
  materialWareList: [],
  materialWareLoading: false,
  deleteMaterialWareId: 0,
  filter: { ...DEFAULT_FILTER }
};

const initMaterialList = list => {
  return list.sort((a, b) => b.quantity - a.quantity);
}

/* eslint-disable default-case, no-param-reassign */
const materialReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_MATERIAL_REQUEST: {
        draft.loading = true;
        break;
      }
      case GET_MATERIAL_SUCCESS: {
        const data = action.payload;
        draft.list = initMaterialList(data);
        draft.loading = false;
        break;
      }
      case GET_MATERIAL_FAILURE: {
        draft.list = [];
        draft.loading = false;
        break;
      }
      case PUT_MATERIAL_REQUEST: {
        draft.materialLoading = true;
        break;
      }
      case PUT_MATERIAL_SUCCESS: {
        draft.materialLoading = false;
        break;
      }
      case PUT_MATERIAL_FAILURE: {
        draft.materialLoading = false;
        break;
      }
      case POST_MATERIAL_WARE_REQUEST: {
        draft.postLoading = true;
        break;
      }
      case POST_MATERIAL_WARE_SUCCESS: {
        draft.postLoading = false;
        break;
      }
      case POST_MATERIAL_WARE_FAILURE: {
        draft.postLoading = false;
        break;
      }
      case GET_MATERIAL_DETAIL_REQUEST: {
        draft.materialLoading = true;
        break;
      }
      case GET_MATERIAL_DETAIL_SUCCESS: {
        const { material, materialWareList } = action.payload;
        draft.material = material;
        draft.materialWareList = materialWareList;
        draft.materialLoading = false;
        break;
      }
      case GET_MATERIAL_DETAIL_FAILURE: {
        draft.material = {};
        draft.materialLoading = false;
        break;
      }
      case GET_MATERIAL_WARE_REQUEST: {
        draft.materialWareLoading = true;
        break;
      }
      case GET_MATERIAL_WARE_SUCCESS: {
        const data = action.payload;
        draft.materialWareList = data;
        draft.materialWareLoading = false;
        break;
      }
      case GET_MATERIAL_WARE_FAILURE: {
        draft.materialWareList = [];
        draft.materialWareLoading = false;
        break;
      }
      case DELETE_MATERIAL_WARE_REQUEST: {
        draft.deleteMaterialWareId = action.payload;
        break;
      }
      case DELETE_MATERIAL_WARE_SUCCESS: {
        draft.deleteMaterialWareId = 0;
        break;
      }
      case DELETE_MATERIAL_WARE_FAILURE: {
        draft.deleteMaterialWareId = 0;
        break;
      }
      case ON_CHANGE_FILTER: {
        const { fieldName, fieldValue } = action.payload;
        if (fieldValue !== undefined) {
          draft.filter[fieldName] = fieldValue;
        } else {
          draft.filter = { ...DEFAULT_FILTER }
        }

        break;
      }
    }
  });

export default materialReducer;
