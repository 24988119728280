import { createSelector } from 'reselect';
import { initialState } from './material.reducer';
import { key } from './index';

const selectDomain = state => state[key] || initialState;


const makeSelectList = () => createSelector(selectDomain, substate => substate.list);

const makeSelectLoading = () => createSelector(selectDomain, substate => substate.loading);

const makeSelectPostLoading = () => createSelector(selectDomain, substate => substate.postLoading);

const makeSelectMaterial = () => createSelector(selectDomain, substate => substate.material);
const makeSelectMaterialLoading = () => createSelector(selectDomain, substate => substate.materialLoading);
const makeSelectMaterialWareList = () => createSelector(selectDomain, substate => substate.materialWareList);
const makeSelectDeleteMaterialWareId = () => createSelector(selectDomain, substate => substate.deleteMaterialWareId);

const makeSelectFilter = () => createSelector(selectDomain, substate => substate.filter);

export {
  selectDomain,
  makeSelectList,
  makeSelectLoading,
  makeSelectPostLoading,
  makeSelectMaterial,
  makeSelectMaterialLoading,
  makeSelectMaterialWareList,
  makeSelectDeleteMaterialWareId,
  makeSelectFilter
};
