import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Row, Col, Form, Input, AutoComplete, Select, Button } from 'antd';
import { validateFields } from '@utils/helper';

import { makeSelectList, makeSelectLoading, makeSelectPostLoading } from '../material.selectors';
import { MATERIAL_TYPE_LIST, MATERIAL_TYPE, MATERIAL_WARE_STATUS_CREATE_LIST, MATERIAL_WARE_STATUS } from '../material.constants';
import { postMaterialWareAction } from '../material.actions';

const { Option } = Select;

class MaterialCreate extends PureComponent {
  constructor() {
    super();
    this.state = {
      searchName: '',
    }
  }
  handleSubmit = async e => {
    e.preventDefault();
    const [err, values] = await validateFields(this.props.form);
    console.log(err, values);
    if (!err) {
      this.props.createMaterialWare(values);
    }
  };
  handleSelectMaterial = value => {
    const { materialList } = this.props;
    const item = materialList.find(x => x.name === value);
    let result = 0;
    if (item) {
      result = item.id;
      this.props.form.setFieldsValue({ type: `${item.type}`, name: item.name });
    }
    this.props.form.setFieldsValue({ materialId: result });
  }
  handleSearchMaterial = value => {
    this.setState({ searchName: value });
    this.props.form.setFieldsValue({ name: value, materialId: 0 });

  }
  render() {
    const { materialList, loading } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { searchName } = this.state;
    const searchSource = searchName ? materialList.filter(x => x.name.includes(searchName)) : materialList;
    const dataSource = searchSource.map(x => x.name);
    const materialId = parseInt(getFieldValue('materialId'), 0);
    return (
      <>
        <Row gutter={16}>
          <Col sm={24}>
            <h2>Nhập hàng</h2>
          </Col>
        </Row>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Row gutter={16}>
            <Col sm={4}>
              <Form.Item label="Tên vải">
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Nhớ chọn hoặc nhập tên vải' }]
                })(
                  <Input style={{ display: 'none' }} />
                )}
                <AutoComplete
                  dataSource={dataSource}
                  onSelect={this.handleSelectMaterial}
                  onSearch={this.handleSearchMaterial}
                  placeholder="Tên vải"
                />
              </Form.Item>
              {getFieldDecorator('materialId', {
                initialValue: 0,
              })(
                <Input style={{ display: 'none' }} />
              )}
            </Col>
            <Col sm={4}>
              <Form.Item label="Số lượng">
                {getFieldDecorator('orderQuantity', {
                  initialValue: 100,
                  rules: [{ required: true, message: 'Nhớ nhập số lượng' }]
                })(<Input type="number" />)}
              </Form.Item>
            </Col>
            <Col sm={4}>
              <Form.Item label="Loại vải">
                {getFieldDecorator('type', {
                  initialValue: `${MATERIAL_TYPE.MAJOR}`
                })(
                  <Select disabled={materialId > 0}>
                    {MATERIAL_TYPE_LIST.map(x =>
                      <Option key={x.value} value={`${x.value}`} > {x.label}</Option>
                    )}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col sm={4}>
              <Form.Item label="Trạng thái">
                {getFieldDecorator('status', {
                  initialValue: `${MATERIAL_WARE_STATUS.PENDING}`
                })(
                  <Select>
                    {MATERIAL_WARE_STATUS_CREATE_LIST.map(x =>
                      <Option key={x.value} value={`${x.value}`} > {x.label}</Option>
                    )}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col sm={4}>
              <Button size="large" loading={loading} disabled={loading} htmlType="submit" type="primary">Nhập</Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

MaterialCreate.propTypes = {
  materialList: PropTypes.array,
  materialLoading: PropTypes.bool,
  loading: PropTypes.bool,
};

MaterialCreate.defaultProps = {
  materialList: [],
  materialLoading: false,
  loading: false
};

const mapStateToProps = createStructuredSelector({
  materialList: makeSelectList(),
  materialLoading: makeSelectLoading(),
  loading: makeSelectPostLoading(),
});

const mapDispatchToProps = dispatch => ({
  createMaterialWare: data => dispatch(postMaterialWareAction(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const MaterialCreateWrapper = Form.create()(MaterialCreate)

export default compose(withConnect, memo)(MaterialCreateWrapper);
