import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Row, Col, Table, Button } from 'antd';
import { getImageUrl, searchText } from '@utils/helper';

import { makeSelectList, makeSelectLoading, makeSelectFilter } from '../product.selectors';
import ProductDetai from '../Detail';
import Filter from './Filter';

class ProductList extends PureComponent {
  constructor() {
    super();
    this.state = {
      productId: 0,
    }
  }
  render() {
    const { list, loading, filter } = this.props;
    let dataSource = list;
    if (filter.name || filter.code || filter.note) {
      dataSource = list.filter(x => {
        let result = true;
        if (filter.name) { result = searchText(x.name, filter.name); }
        if (result && filter.code) { result = searchText(x.code, filter.code); }
        if (result && filter.note) { result = searchText(x.note, filter.note); }
        return result;
      })
    }
    const totalQuantity = dataSource.reduce((subTotal, a) => subTotal + a.quantity, 0);
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id'
      },
      {
        title: 'Hình',
        dataIndex: 'image',
        render: text => <img alt={text} height="100" src={getImageUrl(text)} />
      },
      {
        title: 'Mã SP',
        dataIndex: 'code'
      },
      {
        title: 'Sản phẩm',
        dataIndex: 'name',
      },
      {
        title: `Tồn kho (${totalQuantity})`,
        dataIndex: 'quantity',
        align: 'right'
      },
      {
        title: 'M',
        dataIndex: 'sizeM',
        align: 'right'
      },
      {
        title: 'L',
        dataIndex: 'sizeL',
        align: 'right'
      },
      {
        title: 'XL',
        dataIndex: 'sizeXL',
        align: 'right'
      },
      {
        title: 'XXL',
        dataIndex: 'sizeXXL',
        align: 'right'
      },
      {
        title: 'Định mức',
        dataIndex: 'quota'
      },
      {
        title: 'Ghi chú',
        dataIndex: 'note'
      },
      {
        dataIndex: 'id',
        render: text => <Button icon="edit" onClick={() => this.setState({ productId: text })} />,
      }
    ]
    const pagination = {
      showSizeChanger: true,
    }
    return (
      <>
        <Row gutter={16}>
          <Col sm={24}>
            <h2>Kho Hàng</h2>
          </Col>
        </Row>
        <Filter />
        <Row gutter={16}>
          <Col sm={24}>
            <Table
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              rowKey="id"
              pagination={pagination}
            />
          </Col>
        </Row>
        {this.state.productId > 0 && <ProductDetai productId={this.state.productId} onClose={() => this.setState({ productId: 0 })} />}
      </>
    );
  }
}

ProductList.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  filter: PropTypes.object,
};

ProductList.defaultProps = {
  list: [],
  loading: false,
  filter: {}
};

const mapStateToProps = createStructuredSelector({
  list: makeSelectList(),
  loading: makeSelectLoading(),
  filter: makeSelectFilter(),
});

const mapDispatchToProps = dispatch => ({

});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(ProductList);
