
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import request from '@utils/request';
import { validateFields, formatNumber } from '@utils/helper';
import { Button, Input, InputNumber, Select, Form, Row, Col, Spin, notification } from 'antd';
import './style.less';

const { Option } = Select;

const SAPO_CATEGORY = [1, 3];

function CreditForm({ credit, deptID, form, categoryList, year, month, onSave, onCancel }) {
  const lastCategoryId = parseInt(localStorage.getItem('LAST_CATEGORY_ID') || '1', 0);
  const { getFieldDecorator, getFieldValue } = form;
  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credit])
  const getDayList = () => {
    const today = new Date();
    const result = [];
    for (let i = 1; i <= today.getDate(); i += 1) {
      result.push(i);
    }
    return result;
  }
  let catId = 0;
  const { isLoading, mutate: saveCredit } = useMutation((data) => {
    catId = data.categoryID;
    if (credit.id) {
      return request.put(`v1/credit/${credit.id}`, data);
    }
    return request.post(`v1/credit`, data);
  },
    {
      onSuccess: (response) => {
        onSave();
        localStorage.setItem('LAST_CATEGORY_ID', catId);
        form.resetFields();
      },
    });
  const handleReset = () => {
    onCancel({});
    form.resetFields();
  }
  const handleSubmit = async e => {
    e.preventDefault();
    const [err, values] = await validateFields(form);
    if (!err) {
      if (!values.inAmount && !values.outAmount) {
        return notification.error({ message: "Nhớ nhập số tiền nha" });
      }
      const curDate = new Date();
      const today = new Date(year, month, values.day, curDate.getHours(), curDate.getMinutes());
      const incomeDate = Math.round(today / 1000);
      const postData = {
        ...values,
        deptID,
        year,
        month,
      }
      const categoryID = parseInt(postData.categoryID, 0);
      const orderCode = postData.orderCode ? postData.orderCode.trim().toUpperCase() : '';
      if (SAPO_CATEGORY.includes(categoryID) && orderCode && orderCode.indexOf('SON') !== 0) {
        postData.orderCode = `SON${orderCode}`;
      }
      if (!credit.id || credit.day !== values.day) {
        postData.incomeDate = incomeDate;
      }
      saveCredit(postData);
    }
  }
  const dayList = getDayList();
  const today = new Date().getDate();
  const inA = formatNumber(getFieldValue('inAmount'), '');
  const outA = formatNumber(getFieldValue('outAmount'), '');
  const colWidth = credit.id ? 12 : 6;
  return (
    <Spin spinning={isLoading}>
      <Form className='creditForm' onSubmit={handleSubmit}>
        <p className='creditTitle'>{credit.id ? `Sửa dòng ${credit.id}` : `Nhập mới`}</p>
        <Row gutter={8}>
          <Col sm={colWidth}>
            <Form.Item label="Danh mục">
              {getFieldDecorator(`categoryID`, {
                initialValue: credit.categoryID || lastCategoryId
              })(
                <Select className='filterSelect'>
                  {categoryList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
              )
              }
            </Form.Item>
          </Col>
          <Col sm={colWidth}>
            <Form.Item label="Mã đơn">
              {getFieldDecorator('orderCode', {
                initialValue: credit.orderCode
              })(<Input />)
              }
            </Form.Item>
          </Col>
          <Col sm={colWidth}>
            <Form.Item label={`Thu - ${inA}`}>
              {getFieldDecorator('inAmount', {
                initialValue: credit.inAmount || ''
              })(<InputNumber
                className='filterSelect'
                // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onPressEnter={handleSubmit} />)
              }
            </Form.Item>
          </Col>
          <Col sm={colWidth}>
            <Form.Item label={`Chi - ${outA}`}>
              {getFieldDecorator('outAmount', {
                initialValue: credit.outAmount || ''
              })(<InputNumber
                className='filterSelect'
                // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onPressEnter={handleSubmit} />)
              }
            </Form.Item>
          </Col>
          <Col sm={colWidth * 2}>
            <Form.Item label="Ghi chú">
              {getFieldDecorator('note', {
                initialValue: credit.note
              })(<Input onPressEnter={handleSubmit} />)
              }
            </Form.Item>
          </Col>
          <Col sm={colWidth} style={{ display: credit.id ? 'none' : 'block' }}>
            <Form.Item label="Ngày">
              {getFieldDecorator(`day`, {
                initialValue: credit.day || today
              })(
                <Select className='filterSelect'>
                  {dayList.map(item => <Option key={item} value={item}>{item}</Option>)}
                </Select>
              )
              }
            </Form.Item>
          </Col>
          <Col sm={colWidth}>
            <Button style={{ marginTop: 28 }} type='primary' onClick={handleSubmit}>{credit.id ? 'Cập nhật' : 'Lưu'}</Button>
            <Button style={{ marginTop: 28, marginLeft: 20 }} onClick={handleReset}>{credit.id ? 'Đóng' : 'Xóa'}</Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  )
}

CreditForm.propTypes = {
  form: PropTypes.object,
  credit: PropTypes.object,
  deptID: PropTypes.number,
  year: PropTypes.number,
  month: PropTypes.number,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

CreditForm.defaultProps = {
  form: {},
  credit: {},
  deptID: 0,
  year: 0,
  month: 0,
  onSave: () => { },
  onCancel: () => { }
};

export default Form.create()(CreditForm);
