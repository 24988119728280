import produce from 'immer';
import {
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_FAILURE,
  GET_PREORDER_SUCCESS,
  GET_PREORDER_REQUEST,
  GET_PREORDER_FAILURE,
  POST_PREORDER_SUCCESS,
  POST_PREORDER_REQUEST,
  POST_PREORDER_FAILURE,
  PUT_PREORDER_SUCCESS,
  PUT_PREORDER_REQUEST,
  PUT_PREORDER_FAILURE,
  GET_MATERIAL_WARE_SUCCESS,
  GET_MATERIAL_WARE_REQUEST,
  GET_MATERIAL_WARE_FAILURE,
  GET_MATERIAL_SUCCESS,
  GET_MATERIAL_REQUEST,
  GET_MATERIAL_FAILURE,
  SIZE_COLUMN,
  ON_SELECT_PREORDER,
  GET_EXPORT_LIST_SUCCESS,
  GET_EXPORT_LIST_REQUEST,
  GET_EXPORT_LIST_FAILURE,
  POST_EXPORT_SUCCESS,
  POST_EXPORT_REQUEST,
  POST_EXPORT_FAILURE,
  ON_CHANGE_FILTER
} from './preOrder.constants';

const DEFAULT_FILTER = {
  name: '',
  note: ''
}

export const initialState = {
  list: [],
  loading: false,
  productList: [],
  productLoading: false,
  postPreOrderLoading: false,
  materialList: [],
  materialLoading: false,
  materialWareList: [],
  materialWareLoading: false,
  selectedPreOrder: {},
  selectedPreOrderType: '',
  selectedExportItem: {},
  exportList: [],
  exportLoading: false,
  postExportLoading: false,
  filter: { ...DEFAULT_FILTER }
};

const initData = item => {
  const result = {
    ...item,
    logs: [],
    exportLogs: [],
  }
  if (item.stateLog) {
    let logs = [];
    try {
      logs = JSON.parse(item.stateLog);
    }
    catch (e) {
      console.log(e);
    }
    result.logs = logs;
    let curStateLog = logs.find(x => x.state === item.state);
    if (!curStateLog && item.state > 1) {
      curStateLog = logs.find(x => x.state === item.state - 1);
    }
    if (curStateLog) {
      let total = 0;
      SIZE_COLUMN.forEach(size => {
        total += curStateLog[size];
        result[size] = curStateLog[size];
      });
      result.total = total;
    }
  }
  SIZE_COLUMN.forEach(size => result[`e${size}`] = 0);
  result.exportTotal = 0;
  if (item.exportLog) {
    let logs = [];
    try {
      logs = JSON.parse(item.exportLog);
    }
    catch (e) {
      console.log(e);
    }
    if (logs) {
      let total = 0;
      result.exportLogs = logs.map(log => {
        let subTotal = 0;
        SIZE_COLUMN.forEach(size => {
          subTotal += log[size];
          result[`e${size}`] += log[size];
        });
        total += subTotal;
        return {
          ...log,
          total: subTotal
        }
      })
      result.exportTotal = total;
    }
  }
  return result;
}

/* eslint-disable default-case, no-param-reassign */
const preOrderReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_PRODUCT_REQUEST: {
        draft.productLoading = true;
        break;
      }
      case GET_PRODUCT_SUCCESS: {
        const data = action.payload;
        draft.productList = data;
        draft.productLoading = false;
        break;
      }
      case GET_PRODUCT_FAILURE: {
        draft.productList = [];
        draft.productLoading = false;
        break;
      }
      case GET_PREORDER_REQUEST: {
        draft.loading = true;
        break;
      }
      case GET_PREORDER_SUCCESS: {
        const data = action.payload;
        draft.list = data.map(x => initData(x));
        draft.loading = false;
        break;
      }
      case GET_PREORDER_FAILURE: {
        draft.list = [];
        draft.loading = false;
        break;
      }
      case POST_PREORDER_REQUEST: {
        draft.postPreOrderLoading = true;
        break;
      }
      case POST_PREORDER_SUCCESS: {
        const data = action.payload;
        draft.list.unshift(data);
        draft.postPreOrderLoading = false;
        break;
      }
      case POST_PREORDER_FAILURE: {
        draft.list = [];
        draft.postPreOrderLoading = false;
        break;
      }
      case PUT_PREORDER_REQUEST: {
        draft.postPreOrderLoading = true;
        break;
      }
      case PUT_PREORDER_SUCCESS: {
        const data = action.payload;
        const { list } = state;
        const ind = list.findIndex(x => x.id === data.id);
        draft.list[ind] = data;
        draft.postPreOrderLoading = false;
        break;
      }
      case PUT_PREORDER_FAILURE: {
        draft.list = [];
        draft.postPreOrderLoading = false;
        break;
      }
      case GET_MATERIAL_REQUEST: {
        draft.materialLoading = true;
        break;
      }
      case GET_MATERIAL_SUCCESS: {
        const data = action.payload;
        draft.materialList = data;
        draft.materialLoading = false;
        break;
      }
      case GET_MATERIAL_FAILURE: {
        draft.materialList = [];
        draft.materialLoading = false;
        break;
      }
      case ON_SELECT_PREORDER: {
        const { preOrder, type, exportItem } = action.payload;
        if (type === 'updateExportItem') {
          draft.selectedExportItem = exportItem;
        } else {
          draft.selectedExportItem = {};
        }
        draft.selectedPreOrder = preOrder || {}
        draft.selectedPreOrderType = type || '';
        break;
      }
      case GET_MATERIAL_WARE_REQUEST: {
        draft.materialWareLoading = true;
        break;
      }
      case GET_MATERIAL_WARE_SUCCESS: {
        const data = action.payload;
        draft.materialWareList = data;
        draft.materialWareLoading = false;
        break;
      }
      case GET_MATERIAL_WARE_FAILURE: {
        draft.materialWareList = [];
        draft.materialWareLoading = false;
        break;
      }
      case GET_EXPORT_LIST_REQUEST: {
        draft.exportLoading = true;
        break;
      }
      case GET_EXPORT_LIST_SUCCESS: {
        const data = action.payload;
        draft.exportList = data;
        draft.exportLoading = false;
        break;
      }
      case GET_EXPORT_LIST_FAILURE: {
        draft.exportList = [];
        draft.exportLoading = false;
        break;
      }
      case POST_EXPORT_REQUEST: {
        draft.postExportLoading = true;
        break;
      }
      case POST_EXPORT_SUCCESS: {
        draft.postExportLoading = false;
        break;
      }
      case POST_EXPORT_FAILURE: {
        draft.postExportLoading = false;
        break;
      }
      case ON_CHANGE_FILTER: {
        const { fieldName, fieldValue } = action.payload;
        if (fieldValue !== undefined) {
          draft.filter[fieldName] = fieldValue;
        } else {
          draft.filter = { ...DEFAULT_FILTER }
        }

        break;
      }
    }
  });

export default preOrderReducer;
