import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Upload, Drawer, Form, Input, Select, Button, Table, Modal, Row, Col, Popconfirm } from 'antd';
import { validateFields, formatDate, getImageUrl } from '@utils/helper';

import { makeSelectMaterial, makeSelectMaterialLoading, makeSelectMaterialWareList, makeSelectDeleteMaterialWareId } from '../material.selectors';
import { MATERIAL_TYPE_LIST, MATERIAL_WARE_STATUS } from '../material.constants';
import { getMaterialDetailAction, putMaterialAction, putMaterialWareAction, deleteMaterialWareAction } from '../material.actions';
import './style.less';

const { Option } = Select;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

class MaterialDetail extends PureComponent {
  constructor() {
    super();
    this.state = {
      showModal: false,
      materialWareId: 0,
      code: '',
      quantity: '',
      thumbnail: '',
      loading: false,
    }
    this.file = null;
  }
  componentDidMount() {
    this.props.getDetail(this.props.materialId)
  }
  handleUpdateMaterialWare = async e => {
    const { code, quantity } = this.state;
    this.props.updateMaterialWare({ id: this.state.materialWareId, materialId: this.props.materialId, code, quantity, status: 2 });
    this.setState({ showModal: false });
  }
  handleUpdateMaterial = async (e) => {
    e.preventDefault();
    const [err, values] = await validateFields(this.props.form);
    if (!err) {
      const updateData = {
        id: this.props.materialId,
        ...values,
        files: [this.file],
      }
      this.props.updateItem(updateData);
    }
  }
  beforeUpload = (file, fileList) => {
    console.log(fileList);
    this.file = file;
    getBase64(file, imageUrl =>
      this.setState({
        thumbnail: imageUrl,
        loading: false,
      }),
    );
    return false;
  }
  renderEditButton = record => {
    if (record.preOrderId) return '';
    return <Button icon="edit" onClick={() => this.setState({
      showModal: true,
      materialWareId: record.id,
      quantity: record.quantity,
      code: record.code,
    })} />
  }
  renderDeleteButton = record => {
    if (record.preOrderId) return '';
    const loading = this.props.deleteId === record.id;
    return <Popconfirm
      title={'Chắc mới xóa nha?'}
      onConfirm={() => this.props.deleteItem(record.id)}
      okText="Chắc mà"
      cancelText="Để xem lại"
    >
      <Button loading={loading} disabled={loading} icon="close-circle" style={{ marginLeft: 10 }} type="danger" />
    </Popconfirm>
  }
  render() {
    const { material, materialLoading, materialWareList } = this.props;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: 'Ngày đặt',
        dataIndex: 'createdAt',
        render: text => formatDate(text * 1000)
      },
      {
        title: 'Mã nhập',
        dataIndex: 'code',
      },
      {
        title: 'Số lượng đặt',
        dataIndex: 'orderQuantity'
      },
      {
        title: 'Số lượng thực tế',
        dataIndex: 'quantity'
      },
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        render: (text, record) => MATERIAL_WARE_STATUS.PENDING === text ? <Button type="primary" onClick={() => this.setState({
          showModal: true,
          materialWareId: record.id,
          quantity: record.orderQuantity,
          code: record.code,
        })}>Nhận hàng</Button> : ''
      },
      {
        dataIndex: 'id',
        render: (text, record) => (
          <>
            {this.renderEditButton(record)}
            {this.renderDeleteButton(record)}
          </>
        ),
      }
    ]
    return (
      <Drawer
        title="Cập nhật kho vải"
        placement="right"
        onClose={this.props.onClose}
        visible
        width="50%"
      >
        <Form onSubmit={this.handleUpdateMaterial} loading={materialLoading}>
          <Row gutter={16}>
            <Col sm={12}>
              <div className='materialImageContainer' style={{ backgroundImage: `url(${getImageUrl(material.image)})` }}>
                <Form.Item className='orderImageArea'>
                  {getFieldDecorator('files', {
                    valuePropName: 'fileList',
                  })(
                    <Upload className="orderImageUpload" beforeUpload={this.beforeUpload} showUploadList={false}>
                      <Button icon="upload"></Button>
                    </Upload>,
                  )}
                </Form.Item>
                <img alt={this.state.thumbnail} className='orderImagePreview' src={this.state.thumbnail} />
              </div>
            </Col>
            <Col sm={12}>
              <Form.Item label="Loại vải">
                {getFieldDecorator('type', {
                  initialValue: `${material.type}`
                })(
                  <Select>
                    {MATERIAL_TYPE_LIST.map(x =>
                      <Option key={x.value} value={`${x.value}`} > {x.label}</Option>
                    )}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Tên vải">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Nhớ chọn hoặc nhập tên vải' }],
              initialValue: material.name
            })(
              <Input />
            )}
          </Form.Item>

          <Form.Item>
            <Button disabled={materialLoading} loading={materialLoading} type="primary" htmlType="submit">Lưu</Button>
          </Form.Item>
        </Form>
        <Table dataSource={materialWareList} columns={columns} rowKey="id" />
        {this.state.showModal && <Modal title={`Nhận ${material.name}`} visible={this.state.showModal} onOk={this.handleUpdateMaterialWare} onCancel={() => this.setState({ showModal: false })}>
          <Form.Item label="Mã hàng">
            <Input value={this.state.code} onChange={e => this.setState({ code: e.target.value })} />
          </Form.Item>
          <Form.Item label="Số lượng">
            <Input type="number" value={this.state.quantity} onChange={e => this.setState({ quantity: e.target.value })} />
          </Form.Item>
        </Modal>}
      </Drawer >
    );
  }
}

MaterialDetail.propTypes = {
  materialId: PropTypes.number,
  material: PropTypes.object,
  materialLoading: PropTypes.bool,
  materialWareList: PropTypes.array,
  deleteId: PropTypes.number,
};

MaterialDetail.defaultProps = {
  materialId: 0,
  material: {},
  materialLoading: false,
  materialWareList: [],
  deleteId: 0,
};

const mapStateToProps = createStructuredSelector({
  material: makeSelectMaterial(),
  materialLoading: makeSelectMaterialLoading(),
  materialWareList: makeSelectMaterialWareList(),
  deleteId: makeSelectDeleteMaterialWareId(),
});

const mapDispatchToProps = dispatch => ({
  updateItem: data => dispatch(putMaterialAction(data)),
  getDetail: id => dispatch(getMaterialDetailAction(id)),
  updateMaterialWare: data => dispatch(putMaterialWareAction(data)),
  deleteItem: id => dispatch(deleteMaterialWareAction(id)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const MaterialDetailWrapper = Form.create()(MaterialDetail)

export default compose(withConnect, memo)(MaterialDetailWrapper);
