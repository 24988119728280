import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Drawer, Form, Input, Button, Table } from 'antd';
import * as htmlToImage from 'html-to-image';
import { validateFields } from '@utils/helper';
import { SIZE_COLUMN, PLACE } from '@/global.constants';

import { selectSelectedItem, selectPutLoading, selectItemList } from '../selectors';
import { putItemAction, onSelectItemAction } from '../actions';
import './style.less';


class ExportNoteDetail extends PureComponent {
  getExportName = () => {
    const { item } = this.props;
    const result = `Xuất kho ${PLACE[item.placeId]} ngày ${item.exportDate} (${item.total})`;
    return result;
  }
  handleUpdate = async (e) => {
    e.preventDefault();
    const [err, values] = await validateFields(this.props.form);
    if (!err) {
      const updateData = {
        id: this.props.item.id,
        ...values,
      }
      this.props.updateItem(updateData);
    }
  }
  handleClose = () => {
    this.props.selectItem();
  }
  handleDownload = async () => {
    const dataUrl = await htmlToImage.toJpeg(this.downloadRef);
    const link = document.createElement('a');
    link.download = this.getExportName();
    link.href = dataUrl;
    link.click();
  }
  renderItemList = () => {
    const { itemList } = this.props;
    const columns = [
      {
        title: 'Đơn Hàng',
        dataIndex: 'preOrderId',
        render: text => `DH${`00000${text}`.slice(-5)}`
      },
      {
        title: 'Mẫu',
        dataIndex: 'name'
      },
    ]
    SIZE_COLUMN.forEach(size => {
      columns.push({
        title: size,
        dataIndex: size,
        align: 'right'
      })
    });
    columns.push({
      title: 'Tổng',
      dataIndex: 'total',
      align: 'right'
    });
    return (<Table
      dataSource={itemList}
      columns={columns}
      pagination={{}}
      rowKey="id"
    />)
  }
  renderPrint = () => {
    const { item, itemList } = this.props;
    const list = [];
    itemList.forEach(order => {
      const { productId } = order;
      const eItemInd = list.findIndex(x => x.productId === productId);
      if (eItemInd < 0) {
        list.push({ ...order });
      } else {
        SIZE_COLUMN.forEach(size => list[eItemInd][size] += order[size]);
      }
    })
    return (
      <>
        <Button onClick={this.handleDownload}>Tải phiếu xuất kho</Button>
        <div className='printContainer'>
          <div className='printArea' ref={r => this.downloadRef = r}>
            <h3>Xuất kho {PLACE[item.placeId]} ngày {item.exportDate} ({item.total})</h3>
            {item.note && <p>{item.note}</p>}
            <div>
              {list.map((order, ind) => (
                <div key={order.preOrderId} className="product">
                  <div>{ind + 1} - {order.name} ({order.total})</div>
                  {SIZE_COLUMN.map(size => (
                    <div key={size}>{size.replace('size', '')}{`: `}{order[size]}</div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    )
  }
  render() {
    const { item, putLoading } = this.props;
    const { getFieldDecorator } = this.props.form;
    if (!item.id) return '';
    return (
      <Drawer
        title="Cập nhật phiếu xuất kho"
        placement="right"
        onClose={this.handleClose}
        visible
        width="50%"
      >
        <h3>KHO: {PLACE[item.placeId]}</h3>
        <Form onSubmit={this.handleUpdate}>
          <Form.Item label="Ngày xuất">
            {getFieldDecorator('exportDate', {
              rules: [{ required: true, message: 'Nhớ chọn hoặc nhập ngày xuất' }],
              initialValue: item.exportDate
            })(
              <Input />
            )}
          </Form.Item>
          <Form.Item label="Ghi chú">
            {getFieldDecorator('note', {
              initialValue: item.note || ''
            })(
              <Input.TextArea />
            )}
          </Form.Item>

          <Form.Item>
            <Button disabled={putLoading} loading={putLoading} type="primary" htmlType="submit">Lưu</Button>
          </Form.Item>
        </Form>
        {this.renderItemList()}
        {this.renderPrint()}
      </Drawer>
    );
  }
}

ExportNoteDetail.propTypes = {
  item: PropTypes.object,
  putLoading: PropTypes.bool,
  itemList: PropTypes.array,
};

ExportNoteDetail.defaultProps = {
  item: {},
  putLoading: false,
  itemList: []
};

const mapStateToProps = createStructuredSelector({
  item: selectSelectedItem(),
  putLoading: selectPutLoading(),
  itemList: selectItemList(),
});

const mapDispatchToProps = dispatch => ({
  updateItem: data => dispatch(putItemAction(data)),
  selectItem: () => dispatch(onSelectItemAction({})),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const ExportNoteDetailWrapper = Form.create()(ExportNoteDetail)

export default compose(withConnect, memo)(ExportNoteDetailWrapper);
