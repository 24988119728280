import { createSelector } from 'reselect';
import { initialState } from './preOrder.reducer';
import { key } from './index';

const selectDomain = state => state[key] || initialState;


const makeSelectList = () => createSelector(selectDomain, substate => substate.list);
const makeSelectLoading = () => createSelector(selectDomain, substate => substate.loading);

const makeSelectProductList = () => createSelector(selectDomain, substate => substate.productList);
const makeSelectProductLoading = () => createSelector(selectDomain, substate => substate.productLoading);

const makeSelectPostPreOrderLoading = () => createSelector(selectDomain, substate => substate.postPreOrderLoading);

const makeSelectMaterialList = () => createSelector(selectDomain, substate => substate.materialList);
const makeSelectMaterialLoading = () => createSelector(selectDomain, substate => substate.materialLoading);
const makeSelectMaterialWareList = () => createSelector(selectDomain, substate => substate.materialWareList);
const makeSelectMaterialWareLoading = () => createSelector(selectDomain, substate => substate.materialWareLoading);

const makeSelectPreOrder = () => createSelector(selectDomain, substate => substate.selectedPreOrder);
const makeSelectPreOrderType = () => createSelector(selectDomain, substate => substate.selectedPreOrderType);
const makeSelectExportItem = () => createSelector(selectDomain, substate => substate.selectedExportItem);

const makeSelectExportList = () => createSelector(selectDomain, substate => substate.exportList);
const makeSelectExportLoading = () => createSelector(selectDomain, substate => substate.exportLoading);
const makeSelectPostExportLoading = () => createSelector(selectDomain, substate => substate.postExportLoading);

const makeSelectFilter = () => createSelector(selectDomain, substate => substate.filter);

export {
  selectDomain,
  makeSelectList,
  makeSelectLoading,
  makeSelectProductList,
  makeSelectProductLoading,
  makeSelectPostPreOrderLoading,
  makeSelectMaterialList,
  makeSelectMaterialLoading,
  makeSelectMaterialWareList,
  makeSelectMaterialWareLoading,
  makeSelectPreOrder,
  makeSelectPreOrderType,
  makeSelectExportList,
  makeSelectExportLoading,
  makeSelectPostExportLoading,
  makeSelectFilter,
  makeSelectExportItem
};
