import { key } from './index';

export const GET_LIST_REQUEST = `${key}/GET_LIST_REQUEST`;
export const GET_LIST_SUCCESS = `${key}/GET_LIST_SUCCESS`;
export const GET_LIST_FAILURE = `${key}/GET_LIST_FAILURE`;

export const GET_LIST_ITEM_REQUEST = `${key}/GET_LIST_ITEM_REQUEST`;
export const GET_LIST_ITEM_SUCCESS = `${key}/GET_LIST_ITEM_SUCCESS`;
export const GET_LIST_ITEM_FAILURE = `${key}/GET_LIST_ITEM_FAILURE`;

export const PUT_ITEM_REQUEST = `${key}/PUT_ITEM_REQUEST`;
export const PUT_ITEM_SUCCESS = `${key}/PUT_ITEM_SUCCESS`;
export const PUT_ITEM_FAILURE = `${key}/PUT_ITEM_FAILURE`;

export const ON_CHANGE_FILTER = `${key}/ON_CHANGE_FILTER`;

export const ON_SELECT_ITEM = `${key}/ON_SELECT_ITEM`;