import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { useInjectSaga } from '@utils/injectSaga';
import { useInjectReducer } from '@utils/injectReducer';
import { getProductAction } from './product.actions';
import reducer from './product.reducer';
import saga from './product.saga';

import List from './List';

export const key = 'product';

function Product(props) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    props.getProductList();
  });

  return (
    <>
      <Helmet>
        <title>Kho hàng</title>
      </Helmet>
      <List />
    </>
  );
}

Product.propTypes = {
  getProductList: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = dispatch => ({
  getProductList: (data) => dispatch(getProductAction(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Product);
