import request from '@utils/request';

export function getMaterialApi(params = {}) {
  return request.get('v1/material', { params });
}

export function postMaterialApi(data = {}) {
  return request.post('v1/material', data);
}

export function putMaterialApi(payload) {
  const formData = new FormData();
  const { id, files, ...rest } = payload;
  Object.keys(rest).forEach(key => {
    formData.set(key, payload[key]);
  });
  if (files) {
    formData.append('files', files[0]);
  }
  return request.put(`v1/material/${id}`, formData);
}

export function deleteMaterialApi(id) {
  return request.delete(`v1/material/${id}`);
}

export function getMaterialDetailApi(id) {
  return request.get(`v1/material/${id}`);
}

export function getMaterialWareApi(params = {}) {
  return request.get('v1/material-ware', { params });
}

export function postMaterialWareApi(data = {}) {
  return request.post('v1/material-ware', data);
}

export function putMaterialWareApi(payload) {
  const { id, ...data } = payload;
  return request.put(`v1/material-ware/${id}`, data);
}

export function deleteMaterialWareApi(id) {
  return request.delete(`v1/material-ware/${id}`);
}