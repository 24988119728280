import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { Layout, Menu, Icon } from 'antd';

import { makeSelectUser } from 'global.selectors';
import mainRoutes from 'routes/mainRoutes';

import './style.less';

function Header(props) {
  const location = useLocation();
  const router = mainRoutes.filter(x => x.sidebar);
  const selectedKey = location.pathname || '/';
  return (
    <Layout.Header className="header">
      <Link to="/" className="logo">
        <img src="/logo.png" alt="logo" />
      </Link>
      <Menu
        theme="light"
        mode="horizontal"
        defaultSelectedKeys={[selectedKey]}
        className="headerMenu"
      >
        {router.map(route =>
          !route.auth || (!route.permission && props.user) || props.user ? (
            <Menu.Item key={route.path || '/notfound'}>
              <Link to={route.path || '/notfound'}>
                <Icon type={route.icon} />
                <span>{route.name}</span>
              </Link>
            </Menu.Item>
          ) : (
            <></>
          ),
        )}
      </Menu>
      <span className="avatar" style={{}}>
        <img alt="avatar" src={props.user.avatar || '/favicon.ico'} />
        <span>{props.user.name}</span>
      </span>
    </Layout.Header>
  );
}

Header.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, memo)(Header);
