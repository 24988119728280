
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Row, Col } from 'antd';
import { formatNumber } from '@utils/helper';

const { Option } = Select;
const getDateList = () => {
  const startYear = 2022;
  const startMonth = 11;
  const result = [];
  const today = new Date();
  const curYear = today.getFullYear();
  const curMonth = today.getMonth() + 1;
  for (let i = startYear; i <= curYear; i += 1) {
    let startM = startMonth; let endM = curMonth;
    if (startYear < curYear) {
      if (i === startYear) {
        endM = 12;
      } else if (i === curYear) {
        startM = 1;
      } else {
        startM = 1; endM = 12;
      }
    }
    for (let j = startM; j <= endM; j += 1) {
      result.push({
        year: i,
        month: j,
        id: i * 100 + j
      })
    }
  }
  return result.reverse();
}
const dateList = getDateList();

function Filter({ placeList, onChange, placeId, onChangeDate, cashStatus }) {
  const handleChange = value => {
    localStorage.setItem('LAST_DEPT_ID', value);
    onChange(value);
  }
  const handleChangeDate = id => {
    const item = dateList.find(x => x.id === id);
    if (item) {
      onChangeDate(item);
      console.log(item);
    }
  }
  useEffect(() => {
    onChangeDate(dateList[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Row gutter={16}>
      <Col sm={12}>
        <Select className='filterSelect' placeholder="Bộ phận" onChange={handleChange} value={placeId}>
          {placeList.map(place => <Option key={place.id} value={place.id}>{place.name}</Option>)}
        </Select>
      </Col>
      <Col sm={12}>
        <Select className='filterSelect' placeholder="Thời gian" onChange={handleChangeDate} defaultValue={dateList[0].id}>
          {dateList.map(item => <Option key={item.id} value={item.id}>Tháng {item.month}/{item.year}</Option>)}
        </Select>
      </Col>
      <Col sm={8}>
        Thu: <b>{formatNumber(cashStatus.income)}</b>
      </Col>
      <Col sm={8}>
        Chi: <b>{formatNumber(cashStatus.outcome)}</b>
      </Col>
      <Col sm={8}>
        Tồn: <b>{formatNumber(cashStatus.income - cashStatus.outcome)}</b>
      </Col>
    </Row>

  )
}

Filter.propTypes = {
  placeList: PropTypes.array,
  onChange: PropTypes.func,
  placeId: PropTypes.number,
  onChangeDate: PropTypes.func,
};

Filter.defaultProps = {
  placeList: [],
  onChange: () => { },
  placeId: 1,
  onChangeDate: () => { },
};

export default Filter;
