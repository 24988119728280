import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { useInjectSaga } from '@utils/injectSaga';
import { useInjectReducer } from '@utils/injectReducer';
import { getMaterialAction } from './material.actions';
import reducer from './material.reducer';
import saga from './material.saga';

import List from './List';
import Create from './Create';

export const key = 'material';

function Material(props) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    props.getList();
  });

  return (
    <>
      <Helmet>
        <title>Kho vải</title>
      </Helmet>
      <Create />
      <List />
    </>
  );
}

Material.propTypes = {
  getList: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = dispatch => ({
  getList: () => dispatch(getMaterialAction()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Material);
