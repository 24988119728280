import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router-dom';
import { Layout } from 'antd';

import Header from 'components/Header';
import PrivateRoute from 'components/PrivateRoute';
import mainRoutes from 'routes/mainRoutes';

export default function App() {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Helmet titleTemplate="%s - Yuki Factory" defaultTitle="Yuki Factory">
        <meta name="description" content="A big big tool for Yuki Factory" />
      </Helmet>
      <Header />
      <Layout>
        <Layout>
          <Layout.Content style={{ margin: '16px' }}>
            <Switch>
              {mainRoutes.map(route => (route.auth ? <PrivateRoute key={route.name} {...route} /> : <Route key={route.name} {...route} />))}
            </Switch>
          </Layout.Content>
          <Layout.Footer style={{ textAlign: 'center' }}>Yuki Factory</Layout.Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}
