import { takeLatest, call, put } from 'redux-saga/effects';
import {
  getProductFailure,
  getProductSuccess,
  getPreOrderFailure,
  getPreOrderSuccess,
  postPreOrderFailure,
  postPreOrderSuccess,
  putPreOrderFailure,
  putPreOrderSuccess,
  getMaterialFailure,
  getMaterialSuccess,
  getMaterialWareFailure,
  getMaterialWareSuccess,
  deletePreOrderFailure,
  deletePreOrderSuccess,
  postMaterialWareFailure,
  postMaterialWareSuccess,
  onSelectPreOrderAction,
  getExportListFailure,
  getExportListSuccess,
  postExportFailure,
  postExportSuccess,
} from './preOrder.actions';
import { GET_PRODUCT_REQUEST, GET_PREORDER_REQUEST, POST_PREORDER_REQUEST, PUT_PREORDER_REQUEST, GET_MATERIAL_REQUEST, GET_MATERIAL_WARE_REQUEST, DELETE_PREORDER_REQUEST, POST_MATERIAL_WARE_REQUEST, POST_EXPORT_REQUEST, GET_EXPORT_LIST_REQUEST } from './preOrder.constants';
import { getProductApi, getPreOrderApi, postPreOrderApi, putPreOrderApi, getMaterialWareApi, getMaterialApi, deletePreOrderApi, postMaterialWareApi, postExportApi, getExportListApi } from './preOrder.api';

export function* getProductSaga(action) {
  try {
    const params = action.payload;
    const res = yield call(getProductApi, params);
    yield put(getProductSuccess(res.data));
  } catch (error) {
    yield put(getProductFailure(error));
  }
}

export function* getPreOrderSaga(action) {
  try {
    const params = action.payload;
    const res = yield call(getPreOrderApi, params);
    yield put(getPreOrderSuccess(res.data));
  } catch (error) {
    yield put(getPreOrderFailure(error));
  }
}

export function* postPreOrderSaga(action) {
  try {
    const params = action.payload;
    const res = yield call(postPreOrderApi, params);
    yield put(postPreOrderSuccess(res.data));
    yield call(getPreOrderSaga, {});
  } catch (error) {
    yield put(postPreOrderFailure(error));
  }
}

export function* putPreOrderSaga(action) {
  try {
    const params = action.payload;
    const res = yield call(putPreOrderApi, params);
    yield put(putPreOrderSuccess(res.data));
    yield call(getPreOrderSaga, {});
    yield call(getMaterialSaga, {});
    yield call(getMaterialWareSaga, {});
  } catch (error) {
    yield put(putPreOrderFailure(error));
  }
}

export function* getMaterialSaga(action) {
  try {
    const params = action.payload;
    const res = yield call(getMaterialApi, params);
    yield put(getMaterialSuccess(res.data));
  } catch (error) {
    yield put(getMaterialFailure(error));
  }
}

export function* getMaterialWareSaga(action) {
  try {
    const params = action.payload;
    const res = yield call(getMaterialWareApi, params);
    yield put(getMaterialWareSuccess(res.data));
  } catch (error) {
    yield put(getMaterialWareFailure(error));
  }
}

export function* deletePreOrderSaga(action) {
  try {
    const id = action.payload;
    const res = yield call(deletePreOrderApi, id);
    yield put(deletePreOrderSuccess(res.data));
    yield call(getPreOrderSaga, {});
  } catch (error) {
    yield put(deletePreOrderFailure(error));
  }
}

export function* postMaterialWareSaga(action) {
  try {
    const data = action.payload;
    const res = yield call(postMaterialWareApi, data);
    yield put(postMaterialWareSuccess(res.data));
    yield call(getPreOrderSaga, {});
    yield call(getMaterialSaga, {});
    yield call(getMaterialWareSaga, {});
    yield put(onSelectPreOrderAction({}));
  } catch (error) {
    console.log(error);
    yield put(postMaterialWareFailure(error));
  }
}

export function* getExportListSaga(action) {
  try {
    const params = action.payload;
    const res = yield call(getExportListApi, params);
    yield put(getExportListSuccess(res.data));
  } catch (error) {
    yield put(getExportListFailure(error));
  }
}

export function* postExportSaga(action) {
  try {
    const data = action.payload;
    const res = yield call(postExportApi, data);
    yield put(postExportSuccess(res.data));
    yield call(getPreOrderSaga, {});
    yield put(onSelectPreOrderAction({}));
  } catch (error) {
    console.log(error);
    yield put(postExportFailure(error));
  }
}

export default function* boardSaga() {
  yield takeLatest(GET_PRODUCT_REQUEST, getProductSaga);
  yield takeLatest(GET_PREORDER_REQUEST, getPreOrderSaga);
  yield takeLatest(POST_PREORDER_REQUEST, postPreOrderSaga);
  yield takeLatest(PUT_PREORDER_REQUEST, putPreOrderSaga);
  yield takeLatest(GET_MATERIAL_REQUEST, getMaterialSaga);
  yield takeLatest(GET_MATERIAL_WARE_REQUEST, getMaterialWareSaga);
  yield takeLatest(DELETE_PREORDER_REQUEST, deletePreOrderSaga);
  yield takeLatest(POST_MATERIAL_WARE_REQUEST, postMaterialWareSaga);
  yield takeLatest(GET_EXPORT_LIST_REQUEST, getExportListSaga);
  yield takeLatest(POST_EXPORT_REQUEST, postExportSaga);
}
