import request from '@utils/request';

export function getProductApi(params = {}) {
  return request.get('v1/product', { params });
}

export function getProductDetailApi(id) {
  return request.get(`v1/product/${id}`);
}


export function putProductApi(payload) {
  const formData = new FormData();
  const { id, files, ...rest } = payload;
  Object.keys(rest).forEach(key => {
    formData.set(key, payload[key]);
  });
  if (files) {
    formData.append('files', files[0]);
  }
  return request.put(`v1/product/${id}`, formData);
}