import request from '@utils/request';

export function getProductApi(params = {}) {
  return request.get('v1/product', { params });
}

export function getPreOrderApi(params = {}) {
  return request.get('v1/pre-order', { params });
}

export function postPreOrderApi(payload) {
  const formData = new FormData();
  const { files, ...rest } = payload;
  Object.keys(rest).forEach(key => {
    if (payload[key] !== undefined) {
      formData.set(key, payload[key]);
    }
  });
  if (files) {
    formData.append('files', files[0]);
  }
  return request.post(`v1/pre-order`, formData);
}

export function putPreOrderApi(payload) {
  const formData = new FormData();
  const { id, files, ...rest } = payload;
  Object.keys(rest).forEach(key => {
    if (payload[key] !== undefined) {
      formData.set(key, payload[key]);
    }
  });
  if (files) {
    formData.append('files', files[0]);
  }
  return request.put(`v1/pre-order/${id}`, formData);
}

export function getMaterialWareApi(params = {}) {
  return request.get('v1/material-ware/available', { params });
}

export function deletePreOrderApi(payload) {
  const id = payload;
  return request.delete(`v1/pre-order/${id}`);
}

export function postMaterialWareApi(data = {}) {
  return request.post('v1/material-ware', data);
}

export function getMaterialApi(params = {}) {
  return request.get('v1/material', { params });
}

export function postExportApi(data = {}) {
  if (data.type === 'deleteExportItem') {
    const { id, preOrderId } = data;
    return request.delete(`v1/export-note/items/${id}/${preOrderId}`);
  } else if (data.id) {
    const { id } = data;
    delete data.id;
    return request.put(`v1/export-note/items/${id}`, data);
  }
  return request.post('v1/export-note/items', data);
}

export function getExportListApi(params = {}) {
  return request.get('v1/export-note', { params });
}