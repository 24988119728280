import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Row, Col, Checkbox } from 'antd';
import { searchText } from '@utils/helper';

import { makeSelectList, makeSelectLoading, makeSelectFilter } from '../preOrder.selectors';
import Detail from '../Detail';

class PreOrderList extends PureComponent {
  constructor() {
    super();
    this.state = {
      isApproved: false,
      allowPreProduce: false,
      incompleteExport: true,
    }
  }
  render() {
    const { list, state, filter } = this.props;
    const { isApproved, allowPreProduce, incompleteExport } = this.state;
    let dataSource = list.filter(x => x.state === state);
    if (state === 1) {
      if (isApproved) {
        dataSource = dataSource.filter(x => x.isApproved);
      }
      if (allowPreProduce) {
        dataSource = dataSource.filter(x => !x.materialQuantity || x.materialReceived);
      }
    } else if (state === 5) {
      if (incompleteExport) {
        dataSource = dataSource.filter(x => x.exportTotal < x.total);
      }
    }
    if (filter.name || filter.type || filter.note) {
      dataSource = dataSource.filter(x => {
        let result = true;
        if (filter.name) { result = searchText(x.productName, filter.name); }
        if (result && filter.note) { result = searchText(x.note, filter.note); }
        return result;
      })
    }
    const showFilter = state === 1;
    const showCompleteFilter = state === 5;
    return (
      <>
        {showFilter && <Row style={{ marginBottom: 10 }}>
          <span>Lọc: {`  `}</span>
          <Checkbox checked={isApproved} onChange={e => this.setState({ isApproved: e.target.checked })}>Đã duyệt</Checkbox>
          <Checkbox checked={allowPreProduce} onChange={e => this.setState({ allowPreProduce: e.target.checked })}>Đã nhận vải</Checkbox>
        </Row>}
        {showCompleteFilter && <Row style={{ marginBottom: 10 }}>
          <span>Lọc: {`  `}</span>
          <Checkbox checked={incompleteExport} onChange={e => this.setState({ incompleteExport: e.target.checked })}>Còn tồn kho</Checkbox>
        </Row>}
        <Row gutter={16}>
          {state === 1 && <Col xs={24} sm={12} lg={6} style={{ marginBottom: 16 }}>
            <Detail mode="create" />
          </Col>}
          {dataSource.map(item => (
            <Col xs={24} sm={12} lg={6} key={item.id} style={{ marginBottom: 16 }}>
              <Detail item={item} />
            </Col>
          ))}
        </Row>
      </>
    );
  }
}

PreOrderList.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
};

PreOrderList.defaultProps = {
  list: [],
  loading: false,
};

const mapStateToProps = createStructuredSelector({
  list: makeSelectList(),
  loading: makeSelectLoading(),
  filter: makeSelectFilter(),
});

const mapDispatchToProps = dispatch => ({

});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(PreOrderList);
