import produce from 'immer';
import { SIZE_COLUMN } from '@/global.constants';
import {
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_FAILURE,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_DETAIL_REQUEST,
  GET_PRODUCT_DETAIL_FAILURE,
  POST_PRODUCT_SUCCESS,
  POST_PRODUCT_REQUEST,
  POST_PRODUCT_FAILURE,
  PUT_PRODUCT_SUCCESS,
  PUT_PRODUCT_REQUEST,
  PUT_PRODUCT_FAILURE,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_FAILURE,
  ON_CHANGE_FILTER
} from './product.constants';

const DEFAULT_FILTER = {
  name: '',
  code: '',
  note: ''
}

export const initialState = {
  list: [],
  loading: false,
  product: {},
  productLoading: false,
  process: false,
  filter: { ...DEFAULT_FILTER }
};

const initProductList = list => {
  const result = list.map(x => {
    let quantity = 0;
    SIZE_COLUMN.forEach(size => quantity += x[size]);
    return { ...x, quantity }
  })
  return result.sort((a, b) => b.quantity - a.quantity);
}


/* eslint-disable default-case, no-param-reassign */
const productReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_PRODUCT_REQUEST: {
        draft.loading = true;
        break;
      }
      case GET_PRODUCT_SUCCESS: {
        const data = action.payload;
        draft.list = initProductList(data);
        draft.loading = false;
        break;
      }
      case GET_PRODUCT_FAILURE: {
        draft.list = [];
        draft.loading = false;
        break;
      }
      case GET_PRODUCT_DETAIL_REQUEST: {
        draft.productLoading = true;
        break;
      }
      case GET_PRODUCT_DETAIL_SUCCESS: {
        const data = action.payload;
        draft.product = data;
        draft.productLoading = false;
        break;
      }
      case GET_PRODUCT_DETAIL_FAILURE: {
        draft.list = [];
        draft.productLoading = false;
        break;
      }
      case POST_PRODUCT_REQUEST:
      case PUT_PRODUCT_REQUEST:
      case DELETE_PRODUCT_REQUEST:
        draft.process = true;
        break;
      case POST_PRODUCT_SUCCESS:
      case PUT_PRODUCT_SUCCESS:
      case DELETE_PRODUCT_SUCCESS:
        draft.process = false;
        break;
      case POST_PRODUCT_FAILURE:
      case PUT_PRODUCT_FAILURE:
      case DELETE_PRODUCT_FAILURE:
        draft.process = false;
        break;
      case ON_CHANGE_FILTER: {
        const { fieldName, fieldValue } = action.payload;
        if (fieldValue !== undefined) {
          draft.filter[fieldName] = fieldValue;
        } else {
          draft.filter = { ...DEFAULT_FILTER }
        }

        break;
      }
    }
  });

export default productReducer;
