export const GET_MATERIAL_REQUEST = 'material/GET_MATERIAL_REQUEST';
export const GET_MATERIAL_SUCCESS = 'material/GET_MATERIAL_SUCCESS';
export const GET_MATERIAL_FAILURE = 'material/GET_MATERIAL_FAILURE';

export const GET_MATERIAL_DETAIL_REQUEST = 'material/GET_MATERIAL_DETAIL_REQUEST';
export const GET_MATERIAL_DETAIL_SUCCESS = 'material/GET_MATERIAL_DETAIL_SUCCESS';
export const GET_MATERIAL_DETAIL_FAILURE = 'material/GET_MATERIAL_DETAIL_FAILURE';

export const POST_MATERIAL_REQUEST = 'material/POST_MATERIAL_REQUEST';
export const POST_MATERIAL_SUCCESS = 'material/POST_MATERIAL_SUCCESS';
export const POST_MATERIAL_FAILURE = 'material/POST_MATERIAL_FAILURE';

export const PUT_MATERIAL_REQUEST = 'material/PUT_MATERIAL_REQUEST';
export const PUT_MATERIAL_SUCCESS = 'material/PUT_MATERIAL_SUCCESS';
export const PUT_MATERIAL_FAILURE = 'material/PUT_MATERIAL_FAILURE';

export const DELETE_MATERIAL_REQUEST = 'material/DELETE_MATERIAL_REQUEST';
export const DELETE_MATERIAL_SUCCESS = 'material/DELETE_MATERIAL_SUCCESS';
export const DELETE_MATERIAL_FAILURE = 'material/DELETE_MATERIAL_FAILURE';

export const GET_MATERIAL_WARE_REQUEST = 'material/GET_MATERIAL_WARE_REQUEST';
export const GET_MATERIAL_WARE_SUCCESS = 'material/GET_MATERIAL_WARE_SUCCESS';
export const GET_MATERIAL_WARE_FAILURE = 'material/GET_MATERIAL_WARE_FAILURE';

export const POST_MATERIAL_WARE_REQUEST = 'material/POST_MATERIAL_WARE_REQUEST';
export const POST_MATERIAL_WARE_SUCCESS = 'material/POST_MATERIAL_WARE_SUCCESS';
export const POST_MATERIAL_WARE_FAILURE = 'material/POST_MATERIAL_WARE_FAILURE';

export const PUT_MATERIAL_WARE_REQUEST = 'material/PUT_MATERIAL_WARE_REQUEST';
export const PUT_MATERIAL_WARE_SUCCESS = 'material/PUT_MATERIAL_WARE_SUCCESS';
export const PUT_MATERIAL_WARE_FAILURE = 'material/PUT_MATERIAL_WARE_FAILURE';

export const DELETE_MATERIAL_WARE_REQUEST = 'material/DELETE_MATERIAL_WARE_REQUEST';
export const DELETE_MATERIAL_WARE_SUCCESS = 'material/DELETE_MATERIAL_WARE_SUCCESS';
export const DELETE_MATERIAL_WARE_FAILURE = 'material/DELETE_MATERIAL_WARE_FAILURE';

export const ON_CHANGE_FILTER = 'material/ON_CHANGE_FILTER';

export const MATERIAL_TYPE = {
  MAJOR: 1,
  MINOR: 2,
}

export const MATERIAL_TYPE_LIST = [
  { value: MATERIAL_TYPE.MAJOR, label: 'Vải chính' },
  { value: MATERIAL_TYPE.MINOR, label: 'Vải lót' },
]

export const MATERIAL_TYPE_LABEL = {
  [MATERIAL_TYPE.MAJOR]: 'Vải chính',
  [MATERIAL_TYPE.MINOR]: 'Vải lót',
}

export const MATERIAL_STATUS = {
  AVAILABLE: 1,
  PENDING: 2,
}

export const MATERIAL_STATUS_LABEL = {
  [MATERIAL_STATUS.AVAILABLE]: 'Tồn kho',
  [MATERIAL_STATUS.PENDING]: 'Đang Về',
}

export const MATERIAL_WARE_STATUS = {
  PENDING: 1,
  COMPLETE: 2,
  CANCEL: 3,
}

export const MATERIAL_WARE_STATUS_LIST = [
  { value: [MATERIAL_WARE_STATUS.PENDING], label: 'Đang về' },
  { value: [MATERIAL_WARE_STATUS.COMPLETE], label: 'Đã về' },
  { value: [MATERIAL_WARE_STATUS.CANCEL], label: 'Hủy' },
]

export const MATERIAL_WARE_STATUS_CREATE_LIST = [
  { value: [MATERIAL_WARE_STATUS.PENDING], label: 'Đang về' },
  { value: [MATERIAL_WARE_STATUS.COMPLETE], label: 'Đã về' },
]

export const MATERIAL_WARE_STATUS_LABEL = {
  [MATERIAL_WARE_STATUS.COMPLETE]: 'Đã về',
  [MATERIAL_WARE_STATUS.PENDING]: 'Đang Về',
  [MATERIAL_WARE_STATUS.CANCEL]: 'Đã hủy',
}