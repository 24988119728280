import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { AutoComplete, Form, Input, Modal } from 'antd';
import { validateFields } from '@utils/helper';

import { MATERIAL_TYPE } from '../../Material/material.constants';
import { makeSelectMaterialList, makeSelectPreOrder } from '../preOrder.selectors';
import { postMaterialWareAction } from '../preOrder.actions';

class MaterialModal extends PureComponent {
  constructor() {
    super();
    this.state = {
      searchName: ''
    }
  }
  handleSubmit = async (e) => {
    e.preventDefault();
    const { preOrder, form } = this.props;
    const [err, values] = await validateFields(form);
    if (!err) {
      const updateData = {
        ...values,
        status: 2,
        orderQuantity: preOrder.materialQuantity,
        preOrderId: preOrder.id,
        type: MATERIAL_TYPE.MAJOR
      }
      this.props.createMaterialWare(updateData);

    }
  }
  handleSelectMaterial = value => {
    const { materialList } = this.props;
    const item = materialList.find(x => x.name === value);
    let result = 0;
    if (item) {
      result = item.id;
      this.props.form.setFieldsValue({ type: `${item.type}`, name: item.name });
    }
    this.props.form.setFieldsValue({ materialId: result });
  }
  handleSearchMaterial = value => {
    this.setState({ searchName: value });
    this.props.form.setFieldsValue({ name: value, materialId: 0 });

  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { materialList, preOrder } = this.props;
    const { searchName } = this.state;
    const searchSource = searchName ? materialList.filter(x => x.name.includes(searchName)) : materialList;
    const dataSource = searchSource.map(x => x.name);
    return (
      <Modal title={`Nhận vải`} visible onOk={this.handleSubmit} onCancel={this.props.onCancel}>
        <Form.Item label="Tên vải">
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Nhớ chọn hoặc nhập tên vải' }]
          })(
            <Input style={{ display: 'none' }} />
          )}
          <AutoComplete
            dataSource={dataSource}
            onSelect={this.handleSelectMaterial}
            onSearch={this.handleSearchMaterial}
            placeholder="Tên vải"
            style={{ width: '100%' }}
          />
        </Form.Item>
        {getFieldDecorator('materialId', {
          initialValue: 0,
        })(
          <Input style={{ display: 'none' }} />
        )}
        <Form.Item label="Mã nhập">
          {getFieldDecorator('code')(
            <Input />
          )}
        </Form.Item>
        <Form.Item label="Số lượng">
          {getFieldDecorator('quantity', {
            rules: [{ required: true, message: 'Nhớ nhập số lượng' }],
            initialValue: preOrder.materialQuantity
          })(
            <Input />
          )}
        </Form.Item>
      </Modal>
    );
  }
}

MaterialModal.propTypes = {
  preOrderId: PropTypes.number,
  quantity: PropTypes.number,
};

MaterialModal.defaultProps = {
  preOrderId: 0,
  quantity: 0,
};

const mapStateToProps = createStructuredSelector({
  materialList: makeSelectMaterialList(),
  preOrder: makeSelectPreOrder(),
});

const mapDispatchToProps = dispatch => ({
  createMaterialWare: data => dispatch(postMaterialWareAction(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const MaterialModalWrapper = Form.create()(MaterialModal)

export default compose(withConnect, memo)(MaterialModalWrapper);
