import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { AutoComplete, Form, Input, Modal } from 'antd';
import { validateFields } from '@utils/helper';

import { SIZE_COLUMN } from '../preOrder.constants';
import { makeSelectExportList, makeSelectExportLoading, makeSelectPreOrder, makeSelectPostExportLoading, makeSelectExportItem } from '../preOrder.selectors';
import { getExportListAction, postExportAction } from '../preOrder.actions';

class ExportModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchName: ''
    }
    props.getExportList();
  }

  handleCreate = async (values, preOrder) => {
    let exportNoteName = values.exportNoteName;
    if (exportNoteName) {
      const today = new Date();
      const notes = exportNoteName.includes('/') ? exportNoteName.split('/') : exportNoteName.split('-');
      let day = parseInt(notes[0]);
      let month = notes[1] ? parseInt(notes[1]) : today.getMonth() + 1;
      let year = notes[2] ? parseInt(notes[2]) : today.getFullYear();
      day = `0${day}`.slice(-2);
      month = `0${month}`.slice(-2);
      exportNoteName = `${day}/${month}/${year}`;
      values.exportNoteName = exportNoteName;
      if (!values.exportNoteId) {
        const exportNote = this.props.exportList.find(x => x.exportDate === exportNoteName);
        if (exportNote) {
          values.exportNoteId = exportNote.id;
        }
      }
    }
    const postData = {
      ...values,
      preOrderId: preOrder.id,
      placeId: preOrder.placeId,
    }
    this.props.createExportItem(postData);
  }
  handleUpdate = async (values, preOrder) => {
    const postData = {
      ...values,
      preOrderId: preOrder.id,
      placeId: preOrder.placeId,
      id: this.props.exportItem.id,
    }
    this.props.createExportItem(postData);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { preOrder, form } = this.props;
    const [err, values] = await validateFields(form);
    if (!err) {
      if (this.props.exportItem.id) {
        await this.handleUpdate(values, preOrder);
      } else {
        await this.handleCreate(values, preOrder);
      }
    }
  }
  handleSelectExport = value => {
    const { exportList, preOrder } = this.props;
    const item = exportList.find(x => x.exportDate === value);
    let result = 0;
    if (item) {
      result = item.id;
      const log = preOrder.exportLogs.find(x => x.exportNoteId === item.id);
      if (log) {
        this.props.form.setFieldsValue({
          sizeM: log.sizeM,
          sizeL: log.sizeL,
          sizeXL: log.sizeXL,
          sizeXXL: log.sizeXXL,
        });
      }
      this.props.form.setFieldsValue({ exportNoteName: item.exportDate });
    }
    this.props.form.setFieldsValue({ exportNoteId: result });
  }
  handleSearchExport = value => {
    this.setState({ searchName: value });
    this.props.form.setFieldsValue({ exportNoteName: value, exportNoteId: 0 });

  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { exportList, exportListLoading, preOrder, loading, exportItem } = this.props;
    const { searchName } = this.state;
    const searchSource = searchName ? exportList.filter(x => x.placeId === preOrder.placeId && x.exportDate.includes(searchName)) : exportList;
    const dataSource = searchSource.filter(x => x.placeId === preOrder.placeId).map(x => x.exportDate).reverse();
    let title = "Xuất kho";
    let editMode = false;
    let exportDate = '';
    if (exportItem.id) {
      editMode = true;
      title = `Sửa phiếu xuất kho (${exportItem.id})`;
      const exportNote = exportList.find(x => x.id === exportItem.exportNoteId);
      if (exportNote) exportDate = exportNote.exportDate;
    }
    return (
      <Modal title={title} visible onOk={this.handleSubmit} onCancel={this.props.onCancel} loading={loading}>
        {editMode ?
          <Form.Item label="Ngày xuất (Nhập dạng ngày/tháng)">
            <Input disabled value={exportDate} />
          </Form.Item>
          :
          <Form.Item label="Ngày xuất (Nhập dạng ngày/tháng)">
            {getFieldDecorator('exportNoteName', {
              rules: [{ required: true, message: 'Nhớ chọn hoặc nhập ngày' }]
            })(
              <Input style={{ display: 'none' }} />
            )}
            <AutoComplete
              loading={exportListLoading}
              disabled={exportListLoading}
              dataSource={dataSource}
              onSelect={this.handleSelectExport}
              onSearch={this.handleSearchExport}
              placeholder="Ngày xuất"
              style={{ width: '100%' }}
            />
          </Form.Item>
        }
        {getFieldDecorator('exportNoteId', {
          initialValue: 0,
        })(
          <Input style={{ display: 'none' }} />
        )}
        <Form.Item label="Số lượng" validateStatus="" help="">
          <Input.Group compact>
            {SIZE_COLUMN.map(size => {
              return getFieldDecorator(size, {
                initialValue: exportItem.id ? exportItem[size] : preOrder[size] - preOrder[`e${size}`],
              })(<Input key={size} style={{ width: '25%' }} type="number" />)
            }
            )}
          </Input.Group>
        </Form.Item>
      </Modal>
    );
  }
}

ExportModal.propTypes = {
  exportList: PropTypes.array,
  exportListLoading: PropTypes.bool,
  preOrder: PropTypes.object,
  loading: PropTypes.bool,
};

ExportModal.defaultProps = {
  exportList: [],
  exportListLoading: false,
  preOrder: {},
  loading: false,
};

const mapStateToProps = createStructuredSelector({
  preOrder: makeSelectPreOrder(),
  exportList: makeSelectExportList(),
  loading: makeSelectPostExportLoading(),
  exportListLoading: makeSelectExportLoading(),
  exportItem: makeSelectExportItem(),
});

const mapDispatchToProps = dispatch => ({
  createExportItem: data => dispatch(postExportAction(data)),
  getExportList: data => dispatch(getExportListAction(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const ExportModalWrapper = Form.create()(ExportModal)

export default compose(withConnect, memo)(ExportModalWrapper);
