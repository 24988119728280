import {
  GET_MATERIAL_REQUEST,
  GET_MATERIAL_FAILURE,
  GET_MATERIAL_SUCCESS,
  POST_MATERIAL_REQUEST,
  POST_MATERIAL_FAILURE,
  POST_MATERIAL_SUCCESS,
  PUT_MATERIAL_REQUEST,
  PUT_MATERIAL_FAILURE,
  PUT_MATERIAL_SUCCESS,
  DELETE_MATERIAL_REQUEST,
  DELETE_MATERIAL_FAILURE,
  DELETE_MATERIAL_SUCCESS,
  GET_MATERIAL_WARE_REQUEST,
  GET_MATERIAL_WARE_FAILURE,
  GET_MATERIAL_WARE_SUCCESS,
  POST_MATERIAL_WARE_REQUEST,
  POST_MATERIAL_WARE_FAILURE,
  POST_MATERIAL_WARE_SUCCESS,
  PUT_MATERIAL_WARE_REQUEST,
  PUT_MATERIAL_WARE_FAILURE,
  PUT_MATERIAL_WARE_SUCCESS,
  GET_MATERIAL_DETAIL_REQUEST,
  GET_MATERIAL_DETAIL_FAILURE,
  GET_MATERIAL_DETAIL_SUCCESS,
  DELETE_MATERIAL_WARE_REQUEST,
  DELETE_MATERIAL_WARE_FAILURE,
  DELETE_MATERIAL_WARE_SUCCESS,
  ON_CHANGE_FILTER,
} from './material.constants';


export const getMaterialAction = payload => ({ type: GET_MATERIAL_REQUEST, payload });
export const getMaterialSuccess = payload => ({ type: GET_MATERIAL_SUCCESS, payload });
export const getMaterialFailure = payload => ({ type: GET_MATERIAL_FAILURE, payload });

export const postMaterialAction = payload => ({ type: POST_MATERIAL_REQUEST, payload });
export const postMaterialSuccess = payload => ({ type: POST_MATERIAL_SUCCESS, payload });
export const postMaterialFailure = payload => ({ type: POST_MATERIAL_FAILURE, payload });

export const putMaterialAction = payload => ({ type: PUT_MATERIAL_REQUEST, payload });
export const putMaterialSuccess = payload => ({ type: PUT_MATERIAL_SUCCESS, payload });
export const putMaterialFailure = payload => ({ type: PUT_MATERIAL_FAILURE, payload });

export const deleteMaterialAction = payload => ({ type: DELETE_MATERIAL_REQUEST, payload });
export const deleteMaterialSuccess = payload => ({ type: DELETE_MATERIAL_SUCCESS, payload });
export const deleteMaterialFailure = payload => ({ type: DELETE_MATERIAL_FAILURE, payload });

export const getMaterialWareAction = payload => ({ type: GET_MATERIAL_WARE_REQUEST, payload });
export const getMaterialWareSuccess = payload => ({ type: GET_MATERIAL_WARE_SUCCESS, payload });
export const getMaterialWareFailure = payload => ({ type: GET_MATERIAL_WARE_FAILURE, payload });

export const postMaterialWareAction = payload => ({ type: POST_MATERIAL_WARE_REQUEST, payload });
export const postMaterialWareSuccess = payload => ({ type: POST_MATERIAL_WARE_SUCCESS, payload });
export const postMaterialWareFailure = payload => ({ type: POST_MATERIAL_WARE_FAILURE, payload });

export const putMaterialWareAction = payload => ({ type: PUT_MATERIAL_WARE_REQUEST, payload });
export const putMaterialWareSuccess = payload => ({ type: PUT_MATERIAL_WARE_SUCCESS, payload });
export const putMaterialWareFailure = payload => ({ type: PUT_MATERIAL_WARE_FAILURE, payload });

export const getMaterialDetailAction = payload => ({ type: GET_MATERIAL_DETAIL_REQUEST, payload });
export const getMaterialDetailSuccess = payload => ({ type: GET_MATERIAL_DETAIL_SUCCESS, payload });
export const getMaterialDetailFailure = payload => ({ type: GET_MATERIAL_DETAIL_FAILURE, payload });

export const deleteMaterialWareAction = payload => ({ type: DELETE_MATERIAL_WARE_REQUEST, payload });
export const deleteMaterialWareSuccess = payload => ({ type: DELETE_MATERIAL_WARE_SUCCESS, payload });
export const deleteMaterialWareFailure = payload => ({ type: DELETE_MATERIAL_WARE_FAILURE, payload });

export const onChangeFilterAction = payload => ({ type: ON_CHANGE_FILTER, payload });