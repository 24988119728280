import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { useInjectSaga } from '@utils/injectSaga';
import { useInjectReducer } from '@utils/injectReducer';
import { getListAction } from './actions';
import reducer from './reducer';
import saga from './saga';

import List from './List';
import Detail from './Detail';

export const key = 'exportNote';

function ExportNote(props) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    props.getList();
  });

  return (
    <>
      <Helmet>
        <title>Phiếu Xuất Kho</title>
      </Helmet>
      <List />
      <Detail />
    </>
  );
}

ExportNote.propTypes = {
  getList: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = dispatch => ({
  getList: (data) => dispatch(getListAction(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(ExportNote);
