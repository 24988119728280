import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Row, Col, Input, Button } from 'antd';
import { makeSelectFilter } from '../product.selectors';
import { onChangeFilterAction } from '../product.actions';


class Filter extends PureComponent {
  handleChangeInput = e => {
    const { value, dataset } = e.target;
    const { field } = dataset;
    const { onChangeFilter } = this.props;
    const data = {
      fieldName: field,
      fieldValue: value,
    };
    onChangeFilter(data);
  };


  handleReset = () => {
    this.props.onChangeFilter({});
  };

  render() {
    const { filter } = this.props;
    return (
      <Row gutter={8} style={{ marginBottom: 10 }}>
        <Col sm={6}>
          <Input
            data-field="name"
            placeholder="Tên sản phẩm"
            value={filter.name}
            onChange={this.handleChangeInput}
            onPressEnter={this.handleEnter}
          />
        </Col>
        <Col sm={6}>
          <Input
            data-field="code"
            placeholder="Mã sản phẩm"
            value={filter.code}
            onChange={this.handleChangeInput}
            onPressEnter={this.handleEnter}
          />
        </Col>
        <Col sm={6}>
          <Input
            data-field="note"
            placeholder="Ghi chú"
            value={filter.note}
            onChange={this.handleChangeInput}
            onPressEnter={this.handleEnter}
          />
        </Col>
        <Col sm={4}>
          <Button onClick={this.handleReset}>Reset</Button>
        </Col>
      </Row>
    );
  }
}

Filter.propTypes = {
  onChangeFilter: PropTypes.func,
  filter: PropTypes.objectOf(PropTypes.any),
};

Filter.defaultProps = {
  onChangeFilter: () => { },
  filter: {},
};

const mapStateToProps = createStructuredSelector({
  filter: makeSelectFilter(),
});

const mapDispatchToProps = dispatch => ({
  onChangeFilter: data => dispatch(onChangeFilterAction(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Filter);
