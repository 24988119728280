import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Row, Col, Table, Tag, Button } from 'antd';
import { getImageUrl, searchText } from '@utils/helper';

import { MATERIAL_STATUS, MATERIAL_STATUS_LABEL, MATERIAL_TYPE_LABEL } from '../material.constants';
import { makeSelectList, makeSelectLoading, makeSelectFilter } from '../material.selectors';
import MaterialDetai from '../Detail';
import Filter from './Filter';

class MaterialList extends PureComponent {
  constructor() {
    super();
    this.state = {
      materialId: 0,
    }
  }
  render() {
    const { list, loading, filter } = this.props;
    const pagination = {
      showSizeChanger: true,
    }
    let dataSource = list;
    if (filter.name || filter.type || filter.note) {
      dataSource = list.filter(x => {
        let result = true;
        if (filter.name) { result = searchText(x.name, filter.name); }
        if (result && filter.type) { result = x.type === filter.type }
        if (result && filter.note) { result = searchText(x.note, filter.note); }
        return result;
      })
    }
    const totalQuantity = dataSource.reduce((subTotal, a) => subTotal + a.quantity, 0);
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: 'Hình',
        dataIndex: 'image',
        render: text => <img alt={text} height="100" src={getImageUrl(text)} />
      },
      {
        title: 'Tên',
        dataIndex: 'name',
      },
      {
        title: 'Loại vải',
        dataIndex: 'type',
        render: text => MATERIAL_TYPE_LABEL[text]
      },
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        render: text => MATERIAL_STATUS.PENDING === text ? <Tag color="blue">{MATERIAL_STATUS_LABEL[text]}</Tag> : '',
      },
      {
        title: `Tồn kho (${totalQuantity.toLocaleString()})`,
        dataIndex: 'quantity',
        align: 'right'
      },
      {
        title: 'Ghi chú',
        dataIndex: 'note'
      },
      {
        dataIndex: 'id',
        render: text => <Button icon="edit" onClick={() => this.setState({ materialId: text })} />,
      }
    ]
    return (
      <>
        <Row gutter={16}>
          <Col sm={24}>
            <h2>Kho Vải</h2>
          </Col>
        </Row>
        <Filter />
        <Row gutter={16}>
          <Col sm={24}>
            <Table
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              rowKey="id"
              pagination={pagination}
            />
          </Col>
        </Row>
        {this.state.materialId > 0 && <MaterialDetai materialId={this.state.materialId} onClose={() => this.setState({ materialId: 0 })} />}
      </>
    );
  }
}

MaterialList.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  filter: PropTypes.object,
};

MaterialList.defaultProps = {
  list: [],
  loading: false,
  filter: {}
};

const mapStateToProps = createStructuredSelector({
  list: makeSelectList(),
  loading: makeSelectLoading(),
  filter: makeSelectFilter(),
});

const mapDispatchToProps = dispatch => ({

});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(MaterialList);
