import React, { memo, PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
// import { formatTime } from '@utils/helper';
import { makeSelectExportList } from '../preOrder.selectors';
import { onSelectPreOrderAction, postExportAction } from '../preOrder.actions';
import { Button } from 'antd';

class ExportLog extends PureComponent {
  handleUpdateExportItem = (item) => {
    this.props.selectPreOrder({
      preOrder: this.props.preOrder,
      exportItem: item,
      type: 'updateExportItem'
    });
  }
  handleDeleteExportItem = (item) => {
    this.props.deleteExportItem({
      preOrderId: this.props.preOrder.id,
      id: item.id,
      type: 'deleteExportItem'
    });
  }
  renderContent = item => (
    <div key={item.id}>
      {/* <span className="time">[{formatTime(item.createdAt)}]</span> */}
      <span className="note"><b>{item.total}</b> - M{item.sizeM} L{item.sizeL} XL{item.sizeXL} XXL{item.sizeXXL}</span>
      <Button style={{ marginLeft: 6 }} icon="edit" size="small" onClick={() => this.handleUpdateExportItem(item)} />
      <Button style={{ marginLeft: 6 }} icon="delete" size="small" onClick={() => this.handleDeleteExportItem(item)} />
    </div>
  )
  renderItem = (item) => {
    const { exportList } = this.props;
    const itemDate = item.exportNoteId;
    if (itemDate !== this.lastDate) {
      this.lastDate = itemDate;
      let dateLabel = itemDate;
      const exportItem = exportList.find(x => x.id === itemDate);
      if (exportItem) {
        dateLabel = exportItem.exportDate;
      }
      return (
        <Fragment key={`date-${item.id}`}>
          <div className="logDate">{dateLabel}</div>
          {this.renderContent(item)}
        </Fragment>
      )
    }
    return this.renderContent(item);
  }
  render() {
    const { logs } = this.props;
    if (!logs) return null;
    const dataSource = [...logs];
    dataSource.sort((a, b) => b.createdAt - a.createdAt)
    this.lastDate = '';
    if (dataSource.length < 1) return '';
    return (
      <div className="logArea">
        <h5 style={{ textAlign: 'center' }}>Xuất Kho</h5>
        {dataSource.map(this.renderItem)}
      </div>
    );
  }
}

ExportLog.propTypes = {
  preOrder: PropTypes.object,
  logs: PropTypes.arrayOf(PropTypes.any),
  exportList: PropTypes.arrayOf(PropTypes.any),
};

ExportLog.defaultProps = {
  preOrder: {},
  logs: []
}

const mapStateToProps = createStructuredSelector({
  exportList: makeSelectExportList(),
});

const mapDispatchToProps = dispatch => ({
  selectPreOrder: data => dispatch(onSelectPreOrderAction(data)),
  deleteExportItem: data => dispatch(postExportAction(data))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(withConnect, memo)(ExportLog);