import {
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_FAILURE,
  GET_PRODUCT_SUCCESS,
  GET_PREORDER_REQUEST,
  GET_PREORDER_FAILURE,
  GET_PREORDER_SUCCESS,
  POST_PREORDER_REQUEST,
  POST_PREORDER_FAILURE,
  POST_PREORDER_SUCCESS,
  PUT_PREORDER_REQUEST,
  PUT_PREORDER_FAILURE,
  PUT_PREORDER_SUCCESS,
  GET_MATERIAL_WARE_REQUEST,
  GET_MATERIAL_WARE_SUCCESS,
  GET_MATERIAL_WARE_FAILURE,
  DELETE_PREORDER_REQUEST,
  DELETE_PREORDER_FAILURE,
  DELETE_PREORDER_SUCCESS,
  POST_MATERIAL_WARE_REQUEST,
  POST_MATERIAL_WARE_FAILURE,
  POST_MATERIAL_WARE_SUCCESS,
  ON_SELECT_PREORDER,
  GET_MATERIAL_REQUEST,
  GET_MATERIAL_SUCCESS,
  GET_MATERIAL_FAILURE,
  GET_EXPORT_LIST_REQUEST,
  GET_EXPORT_LIST_FAILURE,
  GET_EXPORT_LIST_SUCCESS,
  POST_EXPORT_REQUEST,
  POST_EXPORT_FAILURE,
  POST_EXPORT_SUCCESS,
  ON_CHANGE_FILTER
} from './preOrder.constants';

export const getProductAction = payload => ({ type: GET_PRODUCT_REQUEST, payload });
export const getProductSuccess = payload => ({ type: GET_PRODUCT_SUCCESS, payload });
export const getProductFailure = payload => ({ type: GET_PRODUCT_FAILURE, payload });

export const getPreOrderAction = payload => ({ type: GET_PREORDER_REQUEST, payload });
export const getPreOrderSuccess = payload => ({ type: GET_PREORDER_SUCCESS, payload });
export const getPreOrderFailure = payload => ({ type: GET_PREORDER_FAILURE, payload });

export const postPreOrderAction = payload => ({ type: POST_PREORDER_REQUEST, payload });
export const postPreOrderSuccess = payload => ({ type: POST_PREORDER_SUCCESS, payload });
export const postPreOrderFailure = payload => ({ type: POST_PREORDER_FAILURE, payload });

export const putPreOrderAction = payload => ({ type: PUT_PREORDER_REQUEST, payload });
export const putPreOrderSuccess = payload => ({ type: PUT_PREORDER_SUCCESS, payload });
export const putPreOrderFailure = payload => ({ type: PUT_PREORDER_FAILURE, payload });

export const getMaterialWareAction = payload => ({ type: GET_MATERIAL_WARE_REQUEST, payload });
export const getMaterialWareSuccess = payload => ({ type: GET_MATERIAL_WARE_SUCCESS, payload });
export const getMaterialWareFailure = payload => ({ type: GET_MATERIAL_WARE_FAILURE, payload });

export const deletePreOrderAction = payload => ({ type: DELETE_PREORDER_REQUEST, payload });
export const deletePreOrderSuccess = payload => ({ type: DELETE_PREORDER_SUCCESS, payload });
export const deletePreOrderFailure = payload => ({ type: DELETE_PREORDER_FAILURE, payload });

export const postMaterialWareAction = payload => ({ type: POST_MATERIAL_WARE_REQUEST, payload });
export const postMaterialWareSuccess = payload => ({ type: POST_MATERIAL_WARE_SUCCESS, payload });
export const postMaterialWareFailure = payload => ({ type: POST_MATERIAL_WARE_FAILURE, payload });

export const onSelectPreOrderAction = payload => ({ type: ON_SELECT_PREORDER, payload });

export const getMaterialAction = payload => ({ type: GET_MATERIAL_REQUEST, payload });
export const getMaterialSuccess = payload => ({ type: GET_MATERIAL_SUCCESS, payload });
export const getMaterialFailure = payload => ({ type: GET_MATERIAL_FAILURE, payload });

export const getExportListAction = payload => ({ type: GET_EXPORT_LIST_REQUEST, payload });
export const getExportListSuccess = payload => ({ type: GET_EXPORT_LIST_SUCCESS, payload });
export const getExportListFailure = payload => ({ type: GET_EXPORT_LIST_FAILURE, payload });

export const postExportAction = payload => ({ type: POST_EXPORT_REQUEST, payload });
export const postExportSuccess = payload => ({ type: POST_EXPORT_SUCCESS, payload });
export const postExportFailure = payload => ({ type: POST_EXPORT_FAILURE, payload });

export const onChangeFilterAction = payload => ({ type: ON_CHANGE_FILTER, payload });