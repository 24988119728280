import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { key } from './index';

const mainDomain = state => state[key] || initialState;


const selectList = () => createSelector(mainDomain, substate => substate.list);
const selectLoading = () => createSelector(mainDomain, substate => substate.loading);

const selectItemList = () => createSelector(mainDomain, substate => substate.itemList);
const selectItemListLoading = () => createSelector(mainDomain, substate => substate.itemListLoading);

const selectPutLoading = () => createSelector(mainDomain, substate => substate.putLoading);

const selectFilter = () => createSelector(mainDomain, substate => substate.filter);
const selectSelectedItem = () => createSelector(mainDomain, substate => substate.selectedItem);


export {
  selectList,
  selectLoading,
  selectFilter,
  selectItemList,
  selectItemListLoading,
  selectPutLoading,
  selectSelectedItem
};
