import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Avatar } from 'antd';

import { makeSelectUserList } from '@/global.selectors';

class UserInfo extends PureComponent {
  renderName = user => user.name;
  renderAvatar = user => (<div className={this.props.className}><Avatar src={user.avatar} /></div>);
  renderBoth = user => (<div className={this.props.className}>
    <Avatar src={user.avatar} />
    <span style={{ marginLeft: 10 }}>{user.name}</span>
  </div>)
  render() {
    const { userId, userList, mode, fieldName } = this.props;
    const user = userList.find(x => x[fieldName] === userId);
    if (!user) { return null; }
    const renderFn = {
      both: this.renderBoth,
      name: this.renderName,
      avatar: this.renderAvatar
    }
    const result = renderFn[mode](user);
    return result;
  }
}

UserInfo.propTypes = {
  userId: PropTypes.number,
  className: PropTypes.string,
  mode: PropTypes.string,
  fieldName: PropTypes.string,
};

UserInfo.defaultProps = {
  userId: 0,
  className: '',
  mode: 'both',
  fieldName: 'id',
};

const mapStateToProps = createStructuredSelector({
  userList: makeSelectUserList(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, memo)(UserInfo);
