import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  getMaterialSuccess,
  getMaterialFailure,
  postMaterialWareSuccess,
  postMaterialWareFailure,
  getMaterialDetailSuccess,
  getMaterialDetailFailure,
  putMaterialSuccess,
  putMaterialFailure,
  putMaterialWareSuccess,
  putMaterialWareFailure,
  deleteMaterialWareSuccess,
  deleteMaterialWareFailure,
} from './material.actions';
import { GET_MATERIAL_REQUEST, GET_MATERIAL_DETAIL_REQUEST, POST_MATERIAL_WARE_REQUEST, PUT_MATERIAL_REQUEST, PUT_MATERIAL_WARE_REQUEST, DELETE_MATERIAL_WARE_REQUEST } from './material.constants';
import { getMaterialApi, postMaterialWareApi, putMaterialWareApi, getMaterialDetailApi, getMaterialWareApi, putMaterialApi, deleteMaterialWareApi } from './material.api';
import { makeSelectMaterial } from './material.selectors';


export function* getMaterialSaga() {
  try {
    const res = yield call(getMaterialApi, {});
    yield put(getMaterialSuccess(res.data));
  } catch (error) {
    console.log(error);
    yield put(getMaterialFailure(error));
  }
}

export function* getMaterialDetailSaga(action) {
  try {
    const id = action.payload;
    const res = yield call(getMaterialDetailApi, id);
    const materialRes = yield call(getMaterialWareApi, { materialId: id });
    const data = {
      material: res.data,
      materialWareList: materialRes.data,
    }
    yield put(getMaterialDetailSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getMaterialDetailFailure(error));
  }
}

export function* putMaterialSaga(action) {
  try {
    const data = action.payload;
    const res = yield call(putMaterialApi, data);
    yield put(putMaterialSuccess(res.data));
    const material = yield select(makeSelectMaterial());
    yield call(getMaterialDetailSaga, { payload: material.id });
    yield call(getMaterialSaga, {});
  } catch (error) {
    console.log(error);
    yield put(putMaterialFailure(error));
  }
}

export function* postMaterialWareSaga(action) {
  try {
    const data = action.payload;
    const res = yield call(postMaterialWareApi, data);
    yield put(postMaterialWareSuccess(res.data));
    yield call(getMaterialSaga);
  } catch (error) {
    console.log(error);
    yield put(postMaterialWareFailure(error));
  }
}

export function* putMaterialWareSaga(action) {
  try {
    const data = action.payload;
    const res = yield call(putMaterialWareApi, data);
    yield put(putMaterialWareSuccess(res.data));
    const material = yield select(makeSelectMaterial());
    yield call(getMaterialDetailSaga, { payload: material.id });
    yield call(getMaterialSaga, {});
  } catch (error) {
    console.log(error);
    yield put(putMaterialWareFailure(error));
  }
}

export function* deleteMaterialWareSaga(action) {
  try {
    const id = action.payload;
    const res = yield call(deleteMaterialWareApi, id);
    yield put(deleteMaterialWareSuccess(res.data));
    const material = yield select(makeSelectMaterial());
    yield call(getMaterialDetailSaga, { payload: material.id });
    yield call(getMaterialSaga, {});
  } catch (error) {
    console.log(error);
    yield put(deleteMaterialWareFailure(error));
  }
}

export default function* boardSaga() {
  yield takeLatest(GET_MATERIAL_REQUEST, getMaterialSaga);
  yield takeLatest(PUT_MATERIAL_REQUEST, putMaterialSaga);
  yield takeLatest(POST_MATERIAL_WARE_REQUEST, postMaterialWareSaga);
  yield takeLatest(GET_MATERIAL_DETAIL_REQUEST, getMaterialDetailSaga);
  yield takeLatest(PUT_MATERIAL_WARE_REQUEST, putMaterialWareSaga);
  yield takeLatest(DELETE_MATERIAL_WARE_REQUEST, deleteMaterialWareSaga);
}
