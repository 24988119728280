import {
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_FAILURE,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_DETAIL_REQUEST,
  GET_PRODUCT_DETAIL_FAILURE,
  GET_PRODUCT_DETAIL_SUCCESS,
  POST_PRODUCT_REQUEST,
  POST_PRODUCT_FAILURE,
  POST_PRODUCT_SUCCESS,
  PUT_PRODUCT_REQUEST,
  PUT_PRODUCT_FAILURE,
  PUT_PRODUCT_SUCCESS,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_SUCCESS,
  ON_CHANGE_FILTER
} from './product.constants';

export const getProductAction = payload => ({ type: GET_PRODUCT_REQUEST, payload });
export const getProductSuccess = payload => ({ type: GET_PRODUCT_SUCCESS, payload });
export const getProductFailure = payload => ({ type: GET_PRODUCT_FAILURE, payload });

export const getProductDetailAction = payload => ({ type: GET_PRODUCT_DETAIL_REQUEST, payload });
export const getProductDetailSuccess = payload => ({ type: GET_PRODUCT_DETAIL_SUCCESS, payload });
export const getProductDetailFailure = payload => ({ type: GET_PRODUCT_DETAIL_FAILURE, payload });

export const postProductAction = payload => ({ type: POST_PRODUCT_REQUEST, payload });
export const postProductSuccess = payload => ({ type: POST_PRODUCT_SUCCESS, payload });
export const postProductFailure = payload => ({ type: POST_PRODUCT_FAILURE, payload });

export const putProductAction = payload => ({ type: PUT_PRODUCT_REQUEST, payload });
export const putProductSuccess = payload => ({ type: PUT_PRODUCT_SUCCESS, payload });
export const putProductFailure = payload => ({ type: PUT_PRODUCT_FAILURE, payload });

export const deleteProductAction = payload => ({ type: DELETE_PRODUCT_REQUEST, payload });
export const deleteProductSuccess = payload => ({ type: DELETE_PRODUCT_SUCCESS, payload });
export const deleteProductFailure = payload => ({ type: DELETE_PRODUCT_FAILURE, payload });

export const onChangeFilterAction = payload => ({ type: ON_CHANGE_FILTER, payload });