export const GET_PRODUCT_REQUEST = 'product/GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'product/GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'product/GET_PRODUCT_FAILURE';

export const GET_PRODUCT_DETAIL_REQUEST = 'product/GET_PRODUCT_DETAIL_REQUEST';
export const GET_PRODUCT_DETAIL_SUCCESS = 'product/GET_PRODUCT_DETAIL_SUCCESS';
export const GET_PRODUCT_DETAIL_FAILURE = 'product/GET_PRODUCT_DETAIL_FAILURE';

export const POST_PRODUCT_REQUEST = 'product/POST_PRODUCT_REQUEST';
export const POST_PRODUCT_SUCCESS = 'product/POST_PRODUCT_SUCCESS';
export const POST_PRODUCT_FAILURE = 'product/POST_PRODUCT_FAILURE';

export const PUT_PRODUCT_REQUEST = 'product/PUT_PRODUCT_REQUEST';
export const PUT_PRODUCT_SUCCESS = 'product/PUT_PRODUCT_SUCCESS';
export const PUT_PRODUCT_FAILURE = 'product/PUT_PRODUCT_FAILURE';

export const DELETE_PRODUCT_REQUEST = 'product/DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'product/DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'product/DELETE_PRODUCT_FAILURE';

export const ON_CHANGE_FILTER = 'product/ON_CHANGE_FILTER';