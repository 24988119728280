import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import queryString from 'query-string';
import { QueryClient, QueryClientProvider } from "react-query";
import { ConnectedRouter } from 'connected-react-router';
import request from '@utils/request';
import { awaitWrapper } from 'utils/helper';

import configureStore from '@utils/configureStore';
import history from '@utils/history';
import App from './App';

const boot = (user = {}, userList = []) => {
  const initialState = {
    global: {
      user,
      userList,
    },
  };
  const store = configureStore(initialState, history);
  const queryClient = new QueryClient();
  const MOUNT_NODE = document.getElementById('root');

  const render = () => {
    ReactDOM.render(
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </QueryClientProvider>
      </Provider>,
      MOUNT_NODE,
    );
  };

  if (module.hot) {
    module.hot.accept(['./App'], () => {
      ReactDOM.unmountComponentAtNode(MOUNT_NODE);
      render();
    });
  }
  render();
};

const init = async () => {
  if (window.location.search.indexOf('?nekot=') > -1) {
    let params = queryString.parse(window.location.search)
    const qToken = params.nekot;
    localStorage.setItem('token', qToken);
    window.location.replace('/');
  }
  const token = localStorage.getItem('token');
  let user = {};
  let userList = [];
  if (token) {
    const [err, res] = await awaitWrapper(request.get('v1/users/current'));
    if (!err) {
      user = res.data;
    }
    const [lErr, lRes] = await awaitWrapper(request.get('v1/users'));
    if (!lErr) {
      userList = lRes.data.map(x => ({
        ...x,
        userId: parseInt(x.userId, 0)
      }));
    }
  }
  boot(user, userList);
};

init();
