export const GET_PRODUCT_REQUEST = 'preOrder/GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'preOrder/GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'preOrder/GET_PRODUCT_FAILURE';

export const GET_PREORDER_REQUEST = 'preOrder/GET_PREORDER_REQUEST';
export const GET_PREORDER_SUCCESS = 'preOrder/GET_PREORDER_SUCCESS';
export const GET_PREORDER_FAILURE = 'preOrder/GET_PREORDER_FAILURE';

export const POST_PREORDER_REQUEST = 'preOrder/POST_PREORDER_REQUEST';
export const POST_PREORDER_SUCCESS = 'preOrder/POST_PREORDER_SUCCESS';
export const POST_PREORDER_FAILURE = 'preOrder/POST_PREORDER_FAILURE';

export const PUT_PREORDER_REQUEST = 'preOrder/PUT_PREORDER_REQUEST';
export const PUT_PREORDER_SUCCESS = 'preOrder/PUT_PREORDER_SUCCESS';
export const PUT_PREORDER_FAILURE = 'preOrder/PUT_PREORDER_FAILURE';

export const GET_MATERIAL_WARE_REQUEST = 'preOrder/GET_MATERIAL_WARE_REQUEST';
export const GET_MATERIAL_WARE_SUCCESS = 'preOrder/GET_MATERIAL_WARE_SUCCESS';
export const GET_MATERIAL_WARE_FAILURE = 'preOrder/GET_MATERIAL_WARE_FAILURE';

export const DELETE_PREORDER_REQUEST = 'preOrder/DELETE_PREORDER_REQUEST';
export const DELETE_PREORDER_SUCCESS = 'preOrder/DELETE_PREORDER_SUCCESS';
export const DELETE_PREORDER_FAILURE = 'preOrder/DELETE_PREORDER_FAILURE';

export const POST_MATERIAL_WARE_REQUEST = 'preOrder/POST_MATERIAL_WARE_REQUEST';
export const POST_MATERIAL_WARE_SUCCESS = 'preOrder/POST_MATERIAL_WARE_SUCCESS';
export const POST_MATERIAL_WARE_FAILURE = 'preOrder/POST_MATERIAL_WARE_FAILURE';

export const GET_MATERIAL_REQUEST = 'preOrder/GET_MATERIAL_REQUEST';
export const GET_MATERIAL_SUCCESS = 'preOrder/GET_MATERIAL_SUCCESS';
export const GET_MATERIAL_FAILURE = 'preOrder/GET_MATERIAL_FAILURE';

export const GET_EXPORT_LIST_REQUEST = 'preOrder/GET_EXPORT_LIST_REQUEST';
export const GET_EXPORT_LIST_SUCCESS = 'preOrder/GET_EXPORT_LIST_SUCCESS';
export const GET_EXPORT_LIST_FAILURE = 'preOrder/GET_EXPORT_LIST_FAILURE';

export const POST_EXPORT_REQUEST = 'preOrder/POST_EXPORT_REQUEST';
export const POST_EXPORT_SUCCESS = 'preOrder/POST_EXPORT_SUCCESS';
export const POST_EXPORT_FAILURE = 'preOrder/POST_EXPORT_FAILURE';

export const ON_SELECT_PREORDER = 'preOrder/ON_SELECT_PREORDER';

export const ON_CHANGE_FILTER = 'preOrder/ON_CHANGE_FILTER';

export const SIZE_COLUMN = ['sizeM', 'sizeL', 'sizeXL', 'sizeXXL']

export const STATE_ENUM = {
  PENDING: 1,
  PREPRODUCE: 2,
  WARE: 3,
  PRODUCE: 4,
  COMPLETE: 5
}

export const STATE = {
  [STATE_ENUM.PENDING]: 'Đặt hàng',
  [STATE_ENUM.PREPRODUCE]: 'Cắt',
  [STATE_ENUM.WARE]: 'Chờ sản xuất',
  [STATE_ENUM.PRODUCE]: 'Sản xuất',
  [STATE_ENUM.COMPLETE]: 'Kho thành phẩm'
}

export const STATE_LIST = [
  { value: STATE_ENUM.PENDING, label: STATE[STATE_ENUM.PENDING] },
  { value: STATE_ENUM.PREPRODUCE, label: STATE[STATE_ENUM.PREPRODUCE] },
  { value: STATE_ENUM.WARE, label: STATE[STATE_ENUM.WARE] },
  { value: STATE_ENUM.PRODUCE, label: STATE[STATE_ENUM.PRODUCE] },
  { value: STATE_ENUM.COMPLETE, label: STATE[STATE_ENUM.COMPLETE] },
]

export const PLACE_LIST = [
  { value: 1, label: 'HCM' },
  { value: 2, label: 'Huế' },
]