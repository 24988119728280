import produce from 'immer';
import { getTotalFromSize } from '@utils/helper';
import {
  GET_LIST_SUCCESS,
  GET_LIST_REQUEST,
  GET_LIST_FAILURE,
  GET_LIST_ITEM_SUCCESS,
  GET_LIST_ITEM_REQUEST,
  GET_LIST_ITEM_FAILURE,
  ON_CHANGE_FILTER,
  ON_SELECT_ITEM,
  PUT_ITEM_SUCCESS,
  PUT_ITEM_REQUEST,
  PUT_ITEM_FAILURE,
} from './constants';

const DEFAULT_FILTER = {
  name: '',
}

export const initialState = {
  list: [],
  loading: false,
  putLoading: false,
  filter: { ...DEFAULT_FILTER },
  selectedItem: {},
  itemList: [],
  itemListLoading: false,
};

/* eslint-disable default-case, no-param-reassign */
const productReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_LIST_REQUEST: {
        draft.loading = true;
        break;
      }
      case GET_LIST_SUCCESS: {
        const data = action.payload;
        const tList = [];
        data.forEach(item => {
          if (tList.includes(item.id)) {
            console.log(item);
          }
          else {
            tList.push(item.id);
          }
        })
        draft.list = data.reverse();
        draft.loading = false;
        break;
      }
      case GET_LIST_FAILURE: {
        draft.list = [];
        draft.loading = false;
        break;
      }
      case ON_CHANGE_FILTER: {
        const { fieldName, fieldValue } = action.payload;
        if (fieldValue !== undefined) {
          draft.filter[fieldName] = fieldValue;
        } else {
          draft.filter = { ...DEFAULT_FILTER }
        }

        break;
      }
      case ON_SELECT_ITEM: {
        draft.selectedItem = { ...action.payload };
        break;
      }
      case GET_LIST_ITEM_REQUEST: {
        draft.itemListLoading = true;
        break;
      }
      case GET_LIST_ITEM_SUCCESS: {
        const data = action.payload;
        draft.itemList = data.map(x => ({
          ...x,
          total: getTotalFromSize(x)
        }));
        draft.itemListLoading = false;
        break;
      }
      case GET_LIST_ITEM_FAILURE: {
        draft.itemList = [];
        draft.itemListLoading = false;
        break;
      }
      case PUT_ITEM_REQUEST: {
        draft.putLoading = true;
        break;
      }
      case PUT_ITEM_SUCCESS: {
        draft.putLoading = false;
        break;
      }
      case PUT_ITEM_FAILURE: {
        draft.putLoading = false;
        break;
      }
    }
  });

export default productReducer;
