import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Card, Input, Alert, Switch, Icon, Form, Upload, Button, AutoComplete, Row, Col, Select, Popconfirm } from 'antd';
import { getImageUrl, formatShortDateTime, validateFields } from '@utils/helper';
import './style.less';
import { makeSelectProductList, makeSelectPostPreOrderLoading, makeSelectMaterialList, makeSelectPreOrder, makeSelectPreOrderType } from '../preOrder.selectors';
import { postPreOrderAction, putPreOrderAction, deletePreOrderAction, onSelectPreOrderAction } from '../preOrder.actions';
import { STATE_LIST, PLACE_LIST, SIZE_COLUMN } from '../preOrder.constants';
import { MATERIAL_TYPE, MATERIAL_TYPE_LABEL } from '../../Material/material.constants';
import LogInfo from './logInfo';
import ExportLog from './exportLog';
import MaterialModal from './materialModal';
import ExportModal from './exportModal';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const { Option } = Select;
class PreOrderDetail extends PureComponent {
  constructor() {
    super();
    this.state = {
      thumbnail: '',
      searchName: '',
      preOrderId: 0,
      quantity: 0,
    }
  }
  beforeUpload = (file, fileList) => {
    console.log(fileList);
    this.file = file;
    getBase64(file, imageUrl =>
      this.setState({
        thumbnail: imageUrl,
        loading: false,
      }),
    );
    return false;
  }
  handleSelectProduct = value => {
    const { productList } = this.props;
    const idString = value.split('-')[0];
    const id = parseInt(idString, 0);
    const item = productList.find(x => x.id === id);
    let result = 0;
    if (item) {
      result = item.id;
      this.props.form.setFieldsValue({ productName: item.name, image: item.image });
      this.setState({
        thumbnail: getImageUrl(item.image),
      })
      this.file = null;
    }
    this.props.form.setFieldsValue({ productId: result });
  }
  handleSearchProduct = value => {
    this.setState({ searchName: value });
    this.props.form.setFieldsValue({ productName: value, productId: 0 });
  }
  handleSubmit = async e => {
    e.preventDefault();
    const [err, values] = await validateFields(this.props.form);
    if (!err) {
      const postData = {
        ...values,
      }
      if (this.file) {
        postData.files = [this.file]
      }
      if (this.props.item.id) {
        postData.id = this.props.item.id;
        this.props.updateItem(postData);
      } else {
        this.props.postItem(postData);
      }
      this.props.form.resetFields();
      this.setState({ searchName: '', thumbnail: '' });
    }
  }
  handleChangeState = state => {
    const { item, updateItem, form } = this.props;
    const { getFieldValue } = form;
    updateItem({
      id: item.id,
      state,
      m: getFieldValue('m'),
      l: getFieldValue('l'),
      xl: getFieldValue('xl'),
      xxl: getFieldValue('xxl'),
    })
  }
  handleChangeApproved = () => {
    const { item, updateItem } = this.props;
    updateItem({
      id: item.id,
      isApproved: !item.isApproved,
    })
  }
  handleChangeArchived = () => {
    const { item, updateItem } = this.props;
    updateItem({
      id: item.id,
      isArchived: true,
    })
  }
  handleReceiveMaterial = () => {
    this.props.selectPreOrder({
      preOrder: this.props.item,
      type: 'receive'
    });
  }
  handleExportItem = () => {
    this.props.selectPreOrder({
      preOrder: this.props.item,
      type: 'export'
    });
  }
  handleCancelReceive = () => {
    this.props.selectPreOrder({});
  }
  renderPlace = item => {
    if (!item.state || item.state < 2) return '';
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        {getFieldDecorator('placeId', {
          initialValue: item.placeId || 1,
        })(
          <Select disabled={item.state > 3} className='placeArea'>
            {PLACE_LIST.map(m => (
              <Option key={m.value} value={m.value}>{m.label}</Option>
            ))}
          </Select>)}
      </>
    )
  }
  renderMaterial = (item) => {
    if (item.state !== 2) return '';
    const materialList = this.props.materialList;
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        <Form.Item>
          <Input.Group compact>
            {getFieldDecorator('majorMaterialId', {
              initialValue: item.majorMaterialId || 0,
            })(
              <Select style={{ width: '75%' }} showSearch filterOption={(input, option) => {
                return option.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}>
                <Option value={0}>Chọn {MATERIAL_TYPE_LABEL[MATERIAL_TYPE.MAJOR]} </Option>
                {materialList.filter(x => x.type === MATERIAL_TYPE.MAJOR).map(m => (
                  <Option key={m.id} value={m.id}>{m.id}-{m.name}-{m.quantity}</Option>
                ))}
              </Select>)}
            {getFieldDecorator('majorMaterialQuantity', {
              initialValue: item.majorMaterialQuantity,
            })(<Input type="number" style={{ width: '25%' }} />)}
          </Input.Group>
        </Form.Item>
        <Form.Item>
          <Input.Group compact>
            {getFieldDecorator('minorMaterialId', {
              initialValue: item.minorMaterialId || 0,
            })(
              <Select style={{ width: '75%' }} showSearch filterOption={(input, option) => {
                return option.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}>
                <Option value={0}>Chọn {MATERIAL_TYPE_LABEL[MATERIAL_TYPE.MINOR]} </Option>
                {materialList.filter(x => x.type === MATERIAL_TYPE.MINOR).map(m => (
                  <Option key={m.id} value={m.id}>{m.id}-{m.name}-{m.quantity}</Option>
                ))}
              </Select>)}
            {getFieldDecorator('minorMaterialQuantity', {
              initialValue: item.minorMaterialQuantity,
            })(<Input type="number" style={{ width: '25%' }} />)}
          </Input.Group>
        </Form.Item>
      </>
    )
  }
  renderNextButton = item => {
    const nextState = item.state + 1;
    const stateItem = STATE_LIST.find(x => x.value === nextState);
    if (stateItem) {
      let disabled = false;
      const { getFieldValue } = this.props.form;
      if (item.state === 1) {
        const materialQuantity = getFieldValue('materialQuantity');
        if (materialQuantity && !item.materialReceived) {
          disabled = true;
        }
      } else if (item.state === 2) {
        const majorMaterialId = item.majorMaterialId;
        const majorMaterialQuantity = item.majorMaterialQuantity;
        const minorMaterialId = item.minorMaterialId;
        const minorMaterialQuantity = item.minorMaterialQuantity;
        if (!majorMaterialId || !majorMaterialQuantity || !minorMaterialId || !minorMaterialQuantity) {
          disabled = true;
        }
      }
      return <Button disabled={disabled} onClick={() => this.handleChangeState(nextState)}>{`=> ${stateItem.label}`}</Button>
    }
    return '';
  }
  renderPrevButton = item => {
    const prevState = item.state - 1;
    const stateItem = STATE_LIST.find(x => x.value === prevState);
    if (stateItem) {
      let disabled = false;
      const { getFieldValue } = this.props.form;
      if (item.state === 1) {
        const materialQuantity = getFieldValue('materialQuantity');
        if (materialQuantity && !item.materialReceived) {
          disabled = true;
        }
      } else if (item.state === 2) {
        const majorMaterialId = item.majorMaterialId;
        const majorMaterialQuantity = item.majorMaterialQuantity;
        const minorMaterialId = item.minorMaterialId;
        const minorMaterialQuantity = item.minorMaterialQuantity;
        if (!majorMaterialId || !majorMaterialQuantity || !minorMaterialId || !minorMaterialQuantity) {
          disabled = true;
        }
      }
      return <Button disabled={disabled} onClick={() => this.handleChangeState(prevState)}>{`${stateItem.label} <=`}</Button>
    }
    return '';
  }
  renderCover = (item) => {
    const { getFieldDecorator } = this.props.form;
    const showLog = Boolean(item.state < 5);
    const showExportLog = Boolean(item.state === 5);
    const showApproved = Boolean(item.state === 1 && item.isApproved);

    return (
      <div className="orderItemContainer" style={{ backgroundImage: `url(${getImageUrl(item.image)})` }}>
        {item.loading && <Icon className="orderItemStatus" type="redo" spin />}
        {item.error && <Icon className="orderItemStatus" type="info-circle" />}
        {item.createdAt && <div className="orderTime">{formatShortDateTime(item.createdAt * 1000)}</div>}
        {item.id && <div className="orderId">DH{`00000${item.id}`.slice(-5)}</div>}
        {showApproved && <div className="orderApproved">Đã Duyệt</div>}
        {getFieldDecorator('isUrgent', {
          initialValue: Boolean(item.isUrgent),
          valuePropName: 'checked'
        })(
          <Switch
            className="orderType isUrgent"
            checkedChildren="Gấp"
            unCheckedChildren="Gấp"
          />
        )}
        <Form.Item className='orderImageArea'>
          {getFieldDecorator('files', {
            valuePropName: 'fileList',
          })(
            <Upload className="orderImageUpload" beforeUpload={this.beforeUpload} showUploadList={false}>
              <Button icon="upload"></Button>
            </Upload>,
          )}
        </Form.Item>
        {this.state.thumbnail ? <img alt={this.state.thumbnail} className='orderImagePreview' src={this.state.thumbnail} /> : ''}
        {showLog ? <LogInfo logs={item.logs} /> : ''}
        {showExportLog ? <ExportLog preOrder={item} logs={item.exportLogs} /> : ''}
        {this.renderPlace(item)}
      </div>
    )
  }

  renderDeleteButton = (item) => {
    if (item.state !== 1) return '';
    return <Popconfirm
      title={'Chắc mới xóa nha?'}
      onConfirm={() => this.props.deleteItem(item.id)}
      okText="Chắc mà"
      cancelText="Để xem lại"
    >
      <Button style={{ marginLeft: 10 }} type="danger">Delete</Button>
    </Popconfirm>
  }

  renderButton = (item, loading) => {
    return <Button loading={loading} disabled={loading} onClick={this.handleSubmit} type="primary">{item.id ? 'Lưu' : 'Tạo'}</Button>
  }
  renderApproveButton = (item, loading) => {
    if (item.state !== 1) return '';
    return <Button loading={loading} disabled={loading} onClick={this.handleChangeApproved} type="primary">{item.isApproved ? 'Bỏ Duyệt' : 'Duyệt'}</Button>
  }
  renderExportButton = (item, loading) => {
    if (item.state !== 5) return '';
    return <Button loading={loading} disabled={loading} onClick={this.handleExportItem} type="primary">Xuất kho</Button>
  }
  renderArchiveButton = (item, loading) => {
    if (item.state !== 5 || item.isArchived) return '';
    return <Button loading={loading} disabled={loading} onClick={this.handleChangeArchived}>Xong</Button>
  }
  renderProductName = item => {
    const name = `${item.productId || item.id} - ${item.name || item.productName}`;
    const code = (item.code || item.productCode) ? ` - [${item.code || item.productCode}]` : '';
    const quota = (item.quota || item.productQuota) ? ` - ${item.quota || item.productQuota}` : '';
    return `${name}${code}${quota}`;
  }

  render() {
    const { item, productList, loading, selectedPreOrderType } = this.props;
    const { getFieldDecorator } = this.props.form;
    const disabled = loading;
    const searchName = this.state.searchName.toLowerCase();
    const searchSource = searchName ? productList.filter(x => x.name.toLowerCase().includes(searchName)) : productList;
    const dataSource = searchSource.map(x => this.renderProductName(x));
    const showReceivedModal = item.id && this.props.selectedPreOrder.id === item.id && selectedPreOrderType === 'receive';
    const showExportModal = item.id && this.props.selectedPreOrder.id === item.id && (selectedPreOrderType === 'export' || selectedPreOrderType === 'updateExportItem');
    return (
      <Card
        className={`orderItemArea${item.loading ? ' loading' : ''}`}
        hoverable
        cover={this.renderCover(item)}
        disabled={disabled}
      >
        {item.error && <Alert className="orderError" type="error" message={item.error} />}
        <Form.Item>
          {getFieldDecorator('productName', {
            rules: [{ required: true, message: 'Nhớ chọn hoặc nhập tên sản phẩm' }],
            initialValue: item.productId ? this.renderProductName(item) : ''
          })(
            <Input style={{ display: 'none' }} />
          )}
          <AutoComplete
            disabled={item.state > 1}
            dataSource={dataSource}
            onSelect={this.handleSelectProduct}
            onSearch={this.handleSearchProduct}
            placeholder="Tên sản phẩm"
            style={{ width: '100%' }}
            defaultValue={item.id ? this.renderProductName(item) : null}
          />

        </Form.Item>
        {getFieldDecorator('productId', {
          initialValue: item.productId,
        })(
          <Input style={{ display: 'none' }} />
        )}
        {getFieldDecorator('image', {
          initialValue: item.image,
        })(
          <Input style={{ display: 'none' }} />
        )}
        {getFieldDecorator('state', {
          initialValue: item.state || 1,
        })(
          <Input style={{ display: 'none' }} />
        )}
        <Form.Item validateStatus="" help="">
          <Input.Group compact>
            {getFieldDecorator('m', {
              initialValue: item.sizeM,
            })(<Input style={{ width: '25%' }} type="number" />)}
            {getFieldDecorator('l', {
              initialValue: item.sizeL
            })(<Input style={{ width: '25%' }} type="number" />)}
            {getFieldDecorator('xl', {
              initialValue: item.sizeXL
            })(<Input style={{ width: '25%' }} type="number" />)}
            {getFieldDecorator('xxl', {
              initialValue: item.sizeXXL
            })(<Input style={{ width: '25%' }} type="number" />)}
          </Input.Group>
        </Form.Item>
        {item.state === 5 && <Form.Item colon={false} validateStatus="" help="" label={`Đã xuất kho: ${item.exportTotal} / ${item.total} (${item.total - item.exportTotal})`}>
          <Input.Group compact>
            {SIZE_COLUMN.map(size => (<Input key={size} style={{ width: '25%' }} type="number" value={item[`e${size}`]} disabled />))}
          </Input.Group>
        </Form.Item>}
        {(!item.state || item.state === 1) && <Form.Item>
          <Input.Group compact>
            {getFieldDecorator('materialQuantity', {
              initialValue: item.materialQuantity
            })(
              <Input
                type="number"
                placeholder="Vải về"
                style={{ width: item.state > 1 ? '100%' : '75%' }}
                disabled={Boolean(item.materialReceived)}
              />
            )}
            {item.state > 1 ? '' : <Button onClick={this.handleReceiveMaterial} style={{ width: '25%' }} disabled={!item.materialQuantity || item.materialReceived}>
              {item.materialReceived ? 'Đã nhận' : 'Nhận vải'}
            </Button>}
          </Input.Group>
        </Form.Item>}
        {this.renderMaterial(item)}
        <Form.Item>
          {getFieldDecorator('note', {
            initialValue: item.note
          })(
            <Input.TextArea
              placeholder="Ghi chú"
            />
          )}
        </Form.Item>
        <Row>
          <Col sm={10}>
            {this.renderButton(item, loading)}
            {this.renderApproveButton(item, loading)}
            {this.renderArchiveButton(item, loading)}
          </Col>
          <Col sm={14} style={{ textAlign: 'right' }}>
            {this.renderPrevButton(item)}
            {this.renderNextButton(item)}
            {this.renderDeleteButton(item)}
            {this.renderExportButton(item, loading)}
          </Col>
        </Row>
        {showReceivedModal && <MaterialModal onCancel={this.handleCancelReceive} />}
        {showExportModal && <ExportModal onCancel={this.handleCancelReceive} />}
      </Card>
    );
  }
}

PreOrderDetail.propTypes = {
  item: PropTypes.object,
  loading: PropTypes.bool,
  materialList: PropTypes.array,
  selectedPreOrderType: PropTypes.string,
};

PreOrderDetail.defaultProps = {
  item: {},
  loading: false,
  materialList: [],
  selectedPreOrder: {},
  selectedPreOrderType: '',
};

const mapStateToProps = createStructuredSelector({
  productList: makeSelectProductList(),
  loading: makeSelectPostPreOrderLoading(),
  materialList: makeSelectMaterialList(),
  selectedPreOrder: makeSelectPreOrder(),
  selectedPreOrderType: makeSelectPreOrderType(),
});

const mapDispatchToProps = dispatch => ({
  postItem: data => dispatch(postPreOrderAction(data)),
  updateItem: data => dispatch(putPreOrderAction(data)),
  deleteItem: data => dispatch(deletePreOrderAction(data)),
  selectPreOrder: data => dispatch(onSelectPreOrderAction(data))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const PreOrderDetailWrapper = Form.create()(PreOrderDetail)

export default compose(withConnect, memo)(PreOrderDetailWrapper);
