import { createSelector } from 'reselect';
import { initialState } from './global.reducer';

const selectGlobal = state => state.global || initialState;
const selectRouter = state => state.router;

const makeSelectUser = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.user,
  );
const makeSelectUserList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.userList,
  );

export { selectGlobal, selectRouter, makeSelectUser, makeSelectUserList };
