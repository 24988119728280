import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Tabs, Row, Col } from 'antd';

import { useInjectSaga } from '@utils/injectSaga';
import { useInjectReducer } from '@utils/injectReducer';
import { getProductAction, getPreOrderAction, getMaterialAction, getMaterialWareAction, getExportListAction } from './preOrder.actions';
import reducer from './preOrder.reducer';
import saga from './preOrder.saga';
import { STATE_LIST } from './preOrder.constants';

import List from './List';
import Filter from './List/Filter';
const { TabPane } = Tabs;
export const key = 'preOrder';


function PreOrder(props) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    props.getProductList();
    props.getPreOrderList({ isArchived: false });
    props.getMaterialList();
    props.getMaterialWareList();
    props.getExportList();
  });


  return (
    <>
      <Helmet>
        <title>Sản xuất</title>
      </Helmet>
      <Row gutter={16}>
        <Col sm={24}>
          <h2>Yuki Factory</h2>
        </Col>
      </Row>
      <Filter />
      <Tabs defaultActiveKey="1">
        {STATE_LIST.map(tab => (
          <TabPane tab={`${tab.label}`} key={tab.value}>
            <List state={tab.value} />
          </TabPane>
        ))}
      </Tabs>
    </>
  );
}

PreOrder.propTypes = {
  getProductList: PropTypes.func,
  getPreOrderList: PropTypes.func,
  getMaterialList: PropTypes.func,
  getMaterialWareList: PropTypes.func,
  getExportList: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = dispatch => ({
  getProductList: (data) => dispatch(getProductAction(data)),
  getPreOrderList: (data) => dispatch(getPreOrderAction(data)),
  getMaterialList: (data) => dispatch(getMaterialAction(data)),
  getMaterialWareList: (data) => dispatch(getMaterialWareAction(data)),
  getExportList: data => dispatch(getExportListAction(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(PreOrder);
