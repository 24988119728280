import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Result, Icon } from 'antd';

import { useInjectSaga } from '@utils/injectSaga';
import { useInjectReducer } from '@utils/injectReducer';
import { makeSelectEmail, makeSelectPassword } from './signin.selectors';
import { postSignInAction, onChangeEmailAction, onChangePasswordAction } from './signin.actions';
import reducer from './signin.reducer';
import saga from './signin.saga';

const key = 'signin';

function SignIn(props) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  return (
    <>
      <Helmet>
        <title>SignIn</title>
        <meta name="description" content="Please contact Admin" />
      </Helmet>
      <Result
        icon={<Icon type="smile" theme="twoTone" />}
        title="Hello, please contact Administrator for Login Information!!!"
      />
    </>
  );
}

SignIn.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  postSignIn: PropTypes.func,
  onChangeEmail: PropTypes.func,
  onChangePassword: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  email: makeSelectEmail(),
  password: makeSelectPassword(),
});

const mapDispatchToProps = dispatch => ({
  postSignIn: () => dispatch(postSignInAction()),
  onChangeEmail: e => dispatch(onChangeEmailAction(e.target.value)),
  onChangePassword: e => dispatch(onChangePasswordAction(e.target.value)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  memo,
)(SignIn);
