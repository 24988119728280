export const PLACE_ENUM = {
  SGN: 1,
  HUI: 2
}

export const PLACE = {
  [PLACE_ENUM.SGN]: 'Sài Gòn',
  [PLACE_ENUM.HUI]: 'Huế'
}

export const PLACE_LIST = [
  { value: PLACE_ENUM.SGN, label: PLACE[PLACE_ENUM.SGN] },
  { value: PLACE_ENUM.HUI, label: PLACE[PLACE_ENUM.HUI] }
]

export const SIZE_COLUMN = ['sizeM', 'sizeL', 'sizeXL', 'sizeXXL']