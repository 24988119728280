import { takeLatest, call, put } from 'redux-saga/effects';
import {
  getProductSuccess,
  getProductFailure,
  getProductDetailSuccess,
  getProductDetailFailure,
  putProductSuccess,
  putProductFailure,
} from './product.actions';
import { GET_PRODUCT_REQUEST, GET_PRODUCT_DETAIL_REQUEST, PUT_PRODUCT_REQUEST } from './product.constants';
import { getProductApi, getProductDetailApi, putProductApi } from './product.api';

export function* getProductSaga(action) {
  try {
    const params = action.payload;
    const res = yield call(getProductApi, params);
    yield put(getProductSuccess(res.data));
  } catch (error) {
    yield put(getProductFailure(error));
  }
}

export function* getProductDetailSaga(action) {
  try {
    const id = action.payload;
    const res = yield call(getProductDetailApi, id);
    yield put(getProductDetailSuccess(res.data));
  } catch (error) {
    yield put(getProductDetailFailure(error));
  }
}

export function* putProductDetailSaga(action) {
  try {
    const data = action.payload;
    const res = yield call(putProductApi, data);
    yield put(putProductSuccess(res.data));
    yield call(getProductSaga, {});
  } catch (error) {
    console.log(error);
    yield put(putProductFailure(error));
  }
}



export default function* boardSaga() {
  yield takeLatest(GET_PRODUCT_REQUEST, getProductSaga);
  yield takeLatest(GET_PRODUCT_DETAIL_REQUEST, getProductDetailSaga);
  yield takeLatest(PUT_PRODUCT_REQUEST, putProductDetailSaga);
}
