import { createSelector } from 'reselect';
import { initialState } from './product.reducer';
import { key } from './index';

const selectDomain = state => state[key] || initialState;


const makeSelectList = () => createSelector(selectDomain, substate => substate.list);
const makeSelectLoading = () => createSelector(selectDomain, substate => substate.loading);

const makeSelectProduct = () => createSelector(selectDomain, substate => substate.product);
const makeSelectProductLoading = () => createSelector(selectDomain, substate => substate.productLoading);

const makeSelectProcess = () => createSelector(selectDomain, substate => substate.process);

const makeSelectFilter = () => createSelector(selectDomain, substate => substate.filter);

export {
  selectDomain,
  makeSelectList,
  makeSelectLoading,
  makeSelectProduct,
  makeSelectProductLoading,
  makeSelectProcess,
  makeSelectFilter
};
