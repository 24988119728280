import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Row, Col, Table, Button } from 'antd';
import { searchText } from '@utils/helper';
import { PLACE } from '@/global.constants';

import { selectList, selectLoading, selectFilter } from '../selectors';
import { onSelectItemAction } from '../actions';
import Filter from './Filter';

class ExportNoteList extends PureComponent {
  render() {
    const { list, loading, filter } = this.props;
    let dataSource = list;
    if (filter.name) {
      dataSource = list.filter(x => {
        let result = true;
        if (filter.name) { result = searchText(x.exportDate, filter.name); }
        if (result && filter.note) { result = searchText(x.note, filter.note); }
        return result;
      })
    }
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: 'Ngày',
        dataIndex: 'exportDate'
      },
      {
        title: 'Kho',
        dataIndex: 'placeId',
        render: text => PLACE[text]
      },
      {
        title: 'Số lượng',
        dataIndex: 'total',
        align: 'right'
      },
      {
        title: 'Ghi chú',
        dataIndex: 'note',
      },
      {
        render: (_, record) => <Button icon="edit" onClick={() => this.props.selectItem(record)} />,
      }
    ]
    const pagination = {
      showSizeChanger: true,
    }
    return (
      <>
        <Row gutter={16}>
          <Col sm={24}>
            <h2>Phiếu Xuất Kho</h2>
          </Col>
        </Row>
        <Filter />
        <Row gutter={16}>
          <Col sm={24}>
            <Table
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              rowKey="id"
              pagination={pagination}
            />
          </Col>
        </Row>
      </>
    );
  }
}

ExportNoteList.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  filter: PropTypes.object,
};

ExportNoteList.defaultProps = {
  list: [],
  loading: false,
  filter: {}
};

const mapStateToProps = createStructuredSelector({
  list: selectList(),
  loading: selectLoading(),
  filter: selectFilter(),
});

const mapDispatchToProps = dispatch => ({
  selectItem: item => dispatch(onSelectItemAction(item)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(ExportNoteList);
