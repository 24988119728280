import { takeLatest, call, put } from 'redux-saga/effects';
import {
  getListSuccess,
  getListFailure,
  getListItemSuccess,
  getListItemFailure,
  putItemSuccess,
  putItemFailure,
  onSelectItemAction,
} from './actions';
import { GET_LIST_REQUEST, ON_SELECT_ITEM, PUT_ITEM_REQUEST } from './constants';
import { getListApi, getListItemApi, putItemApi } from './api';

export function* getListSaga(action) {
  try {
    const params = action.payload;
    const res = yield call(getListApi, params);
    yield put(getListSuccess(res.data));
  } catch (error) {
    yield put(getListFailure(error));
  }
}

export function* onSelectItemSaga(action) {
  const item = action.payload;
  if (item.id) {
    try {
      const res = yield call(getListItemApi, item.id);
      yield put(getListItemSuccess(res.data));
    } catch (error) {
      yield put(getListItemFailure(error));
    }
  }
}

export function* putItemSaga(action) {
  try {
    const data = action.payload;
    const res = yield call(putItemApi, data);
    yield put(putItemSuccess(res.data));
    yield put(onSelectItemAction({}));
  } catch (error) {
    yield put(putItemFailure(error));
  }
}

export default function* boardSaga() {
  yield takeLatest(GET_LIST_REQUEST, getListSaga);
  yield takeLatest(PUT_ITEM_REQUEST, putItemSaga);
  yield takeLatest(ON_SELECT_ITEM, onSelectItemSaga);
}
