
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import querystring from 'query-string';
import request from '@utils/request';
import { formatNumber } from '@utils/helper';
import { Table, Row, Col, Spin } from 'antd';
import './style.less';

function CreditStat({ deptID, categoryList, year, month, onClose }) {
  const [incomeList, setIncomeList] = useState([]);
  const [outcomeList, setOutcomeList] = useState([]);
  const { mutate: getStat, isLoading } = useMutation(({ deptID, year, month }) => {
    const option = {
      deptID,
      year,
      month
    }
    const qs = querystring.stringify(option);
    return request.get(`v1/credit/categories/statistic?${qs}`);
  },
    {
      onSuccess: (response) => {
        const { data } = response;
        initData(data);
      },
    });
  useEffect(() => {
    getStat({ deptID, month, year });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const initData = (data) => {
    const income = [];
    const outcome = [];
    data.forEach(item => {
      const { categoryID } = item;
      const category = categoryList.find(x => x.id === categoryID);
      const categoryName = category ? category.name : categoryID;
      income.push({
        categoryID,
        categoryName,
        amount: parseInt(item.income, 0)
      });
      outcome.push({
        categoryID,
        categoryName,
        amount: parseInt(item.outcome, 0)
      });
    })
    setIncomeList(income.filter(x => x.amount));
    setOutcomeList(outcome.filter(x => x.amount));
  }
  const columns = [
    {
      dataIndex: 'categoryName',
      title: 'Danh mục'
    },
    {
      dataIndex: 'amount',
      title: 'Tổng',
      align: 'right',
      render: formatNumber
    }
  ]
  return (
    <Spin spinning={isLoading}>
      <h3>Tháng {month}/{year}</h3>
      <Row gutter={8}>
        <Col sm={12}>
          <h4>Thu</h4>
          <Table columns={columns} dataSource={incomeList} pagination={false} />
        </Col>
        <Col sm={12}>
          <h4>Chi</h4>
          <Table columns={columns} dataSource={outcomeList} pagination={false} />
        </Col>
      </Row>
    </Spin>
  )
}

CreditStat.propTypes = {
  deptID: PropTypes.number,
  year: PropTypes.number,
  month: PropTypes.number,
  onClose: PropTypes.func,
};

CreditStat.defaultProps = {
  deptID: 0,
  year: 0,
  month: 0,
  onClose: () => { }
};

export default CreditStat;
