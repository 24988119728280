import Material from 'containers/Material';
import PreOrder from 'containers/PreOrder';
import Product from 'containers/Product';
import ExportNote from 'containers/ExportNote';
import SignIn from 'containers/SignIn';
import CashBook from 'containers/CashBook';
import NotFound from 'containers/NotFound';

const mainRoutes = [
  {
    exact: true,
    path: '/',
    name: 'Home',
    icon: 'home',
    component: PreOrder,
    auth: true,
    sidebar: true
  },
  {
    exact: true,
    path: '/material',
    name: 'Kho vải',
    icon: 'pic-center',
    component: Material,
    auth: true,
    sidebar: true
  },
  {
    exact: true,
    path: '/product',
    name: 'Sản phẩm',
    icon: 'database',
    component: Product,
    auth: true,
    sidebar: true
  },
  {
    exact: true,
    path: '/export-note',
    name: 'Phiếu xuất kho',
    icon: 'database',
    component: ExportNote,
    auth: true,
    sidebar: true
  },
  {
    exact: true,
    path: '/cash-book',
    name: 'Thu Chi',
    icon: 'database',
    component: CashBook,
    auth: true,
    sidebar: true
  },
  {
    path: '/signin',
    name: 'Sign In',
    icon: 'login',
    component: SignIn,
  },
  {
    path: '',
    name: 'Not Found',
    icon: 'close-circle',
    component: NotFound,
  },
];

export default mainRoutes;
