import request from '@utils/request';

export function getListApi(params = {}) {
  return request.get('v1/export-note', { params });
}

export function putItemApi(data = {}) {
  const { id, ...rest } = data;
  return request.put(`v1/export-note/${id}`, rest);
}

export function getListItemApi(id) {
  return request.get(`v1/export-note/${id}/items`);
}